import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../utils/loader';
import axios from 'axios';
import Config from '../config/env'


function ManpowerUpload() {

    const [loader, setLoader] = useState(false)
    const [file, setFile] =  useState('')


    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    };

    const uploadExcel = async () => {
        if (!file) {
            toast.error('Please select a file to upload');
            return;
        }

        setLoader(true);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${Config.new.site_api}/Avanti/uploadManpowerData`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.code === 200) {
                toast.success('Upload successfully');
            } else {
                toast.error('Upload failed');
            }
        } catch (error) {
            toast.error('API Error: ' + error.message);
        } finally {
            setLoader(false);
        }
    };

    return (
        <>

            {loader && <Loader />}
            <ToastContainer />

            <div className="container mt-4">
                <h4 className="text-center mb-4">Upload Manpower</h4>
                <div className="mb-3">
                    <label className="form-label"> <h5>Upload Manpower Data :-</h5>

                        {/* <input type="file" accept=".xlsx, .xls" className="form-control" onChange={handleFileChange} /> */}
                        <input type="file" accept=".xlsx, .xls" className="form-control" onChange={(e)=>{handleFileChange(e)}} />

                    </label>
                    {/* <button className='mainbtn ml-5' onClick={() => { setUploadExcelModel(true) }} disabled={isUploadButtonDisabled}>Upload</button> */}
                    <button className='mainbtn ml-5' onClick={() => uploadExcel()}>Upload</button>
                </div>
            </div>
        </>
    )
}

export default ManpowerUpload
