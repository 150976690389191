// import React from "react"
// import {  } from "react";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import API from '../utils/apiCalling';
import Loader from '../utils/loader';
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';


const DownloadExcel = () => {
    const api = new API
    const url = useLocation()
    const [loader, setLoader] = useState(false)
    const [header, setHeader] = useState([
        "QDId", "Name"
    ])
    const [data1, setData] = useState([])
    const [findData, setFindData] = useState({})
    let columns
    (url.pathname === '/downloadYardexcel' || url.pathname === '/downloadAgencyexcel') ?
        columns = [{
            name: "QDId",
            selector: "QDId",
            sortable: true
        },
        {
            name: "Date",
            selector: "Date",
            sortable: true
        },
        {
            name: "Auditor Name",
            selector: "Auditor Name",
            sortable: true
        }]
        :
        columns = [{
            name: "QDId",
            selector: "QDId",
            sortable: true
        },
        {
            name: "Name of Reviewer",
            selector: "Name of Reviewer",
            sortable: true
        },
        {
            name: "Date",
            selector: "Date",
            sortable: true
        },
        {
            name: "Enter Partner Name",
            selector: "Enter Partner Name",
            sortable: true
        }
        ]


    useEffect(() => {
        // getData()

    }, []);


    const getData = async () => {
        console.log(url)
        let result
        let data
        if (url.pathname == "/downloadAgencyexcel" || url.pathname == "/downloadYardexcel") {
            if (url.pathname == "/downloadYardexcel") {
                data = { type: 'yard' }
            } else {
                data = { type: 'agency' }
            }

            result = await api.get('http://api.doyoursurvey.com:3009/Idfc/downloadExcel', { haveParams: true }, data)
        } else {

            result = await api.get("https://api.doyoursurvey.com:3009/Avanti/downloadExcel")
        }

        if (result.code === 200) {
            // setData(result.data)

            headerData(result.data)
            setTimeout(() => {
                responseData(result.data)
            }, 2000);

        }
    }

    const headerData = async (data) => {
        let filterResponse = [{ label: "QDId", key: "Id" },
        // { label: "Name", key: "Name" },
        { label: "Date", key: "date" }]

        console.log("jjj", url.pathname)
        if (url.pathname === "/downloadAgencyexcel" || url.pathname === "/downloadYardexcel") {
            data && data.length > 0 && data[0].questions.map((que) => {

                filterResponse.push({ label: que.question, key: que.question })
                header.push(que.question)
            })
        } else {

            data && data.length > 0 && data[0].questions.map((que) => {

                filterResponse.push({ label: que.que_message, key: que.que_message })
                header.push(que.que_message)
            })
        }

    }

    const responseData = (data) => {

        var filterData
        data && data.length > 0 && data.map((e) => {
            let date = e.date
            date = date.split('T')[0]

            filterData = {
                QDId: e.Id,
                Date: date
            }
            if (url.pathname === "/downloadAgencyexcel" || url.pathname === "/downloadYardexcel") {
                e && e.questions.length > 0 && e.questions.map((que) => {

                    filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? que.answer : que.answer
                })
            } else {
                e && e.questions.length > 0 && e.questions.map((que) => {

                    if (Array.isArray(que.answer)) {
                        filterData[que.que_message] = filterData[que.que_message] ? filterData[que.que_message] : que.answer.join(', ');
                    } else {
                        filterData[que.que_message] = filterData[que.que_message] ? filterData[que.que_message] : que.answer;
                    }

                    // filterData[que.que_message] = filterData[que.que_message] ? filterData[que.que_message] : (que.answer !== undefined) ? que.answer : que.answer.join('')
                    // data1.push(que.answer[0])
                })
            }

            data1.push(filterData)


            // setData(filterData)
            // { (data1.length>7)&& (columns.length>81) && setLoader(false)}
            setLoader(false)
            console.log("hii", filterData)
        })
        setTimeout(() => {

        }, 5000);

    }

    const tableData = {
        columns,
        data1
    };

    const findedData = async () => {
        if (findData) {
            setLoader(true)
            let result = await api.post('https://api.doyoursurvey.com:3009/Avanti/downloadFilterResponse', findData)

            if (result.code === 200) {
                data1.length = 0
                responseData(result.data)
            } else if (result.code === 201) {
                data1.length = 0
                setLoader(false)
                // responseData(result.data)
            }
        }
    }


    const downloadResponseExcel = () => {
        // Create a new workbook and a new worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data1);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Create a Blob and a link to download
        const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = 'surveyWiseUserResponseFullDetails.xlsx'; // Specify the filename

        // Append to the document and trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadArtifactExcel = async () => {
        // let findData ={
        //     filter:"gufspl"
        // }
        if (findData) {
            let result = await api.post('https://api.doyoursurvey.com:3009/Avanti/getIsImageUploaded', findData)
            // console.log(result, "rrrrrrrrrrrrrr")
            if (result.code == 200) {
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(result.data);

                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                // Generate buffer
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                // Create a Blob and a link to download
                const file = new Blob([excelBuffer], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(file);
                link.download = 'ArtifactsDetails.xlsx'; // Specify the filename

                // Append to the document and trigger download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast.error(result.message)
            }
        }
    }

    const setPayloadData = (type, value) => {
        if (value !== '') {
            findData.answer = true
            if (type === "partner") {
                findData.partner = value
            } else {
                findData.location = value
            }
        }
    }

    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            {/* <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(url.pathname === '/downloadAgencyexcel' || url.pathname === '/downloadYardexcel') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">
                        </ul>
                    </div></nav>
            </section> */}
            {loader && <Loader />}
            <div>
                <div className="row">
                    <div className='col-md-4 mb-3 pt-2'>
                        <label className='mb-2 d-block'>Enter QD ID</label>
                        <div className='input-group w-75'>
                            <input className='form-control' type='text' onChange={(e) => findData.Id = e.target.value} placeholder='Search QD ID...' />
                        </div>
                    </div>
                    <div className='col-md-4 mb-3 pt-2'>
                        <label className='mb-2 d-block'>Enter Partner Name</label>
                        <div className='input-group w-75'>
                            <input className='form-control ' type='text' onChange={(e) => setPayloadData("partner", e.target.value)} placeholder='Search...' />
                        </div>
                    </div>
                    <div className='col-md-4 mb-3 pt-2'>
                        <label className='mb-2 d-block'>Enter Location</label>
                        <div className='input-group w-75'>
                            <input className='form-control' type='text' onChange={(e) => setPayloadData("location", e.target.value)} placeholder='Search...' />
                        </div></div>
                    <div className='col-md-4 mb-3 pt-2'>
                        <label className='mb-2 d-block'>Enter Start Date</label>
                        <div className='input-group w-75'>
                            <input className='form-control ' type='date' onChange={(e) => findData.date = e.target.value} placeholder='Search Date...' />
                        </div></div>
                    <div className='col-md-4 mb-3 pt-2'>
                        <label className='mb-2 d-block'>Enter End Date</label>
                        <div className='input-group w-75'>
                            <input className='form-control ' type='date' onChange={(e) => findData.toDate = e.target.value} placeholder='Search Date...' />
                        </div></div>
                        <div className='col-md-4 col-3 pt-3'>
                    <button className='mainbtn m-4 w-75' onClick={findedData}>Find</button>
                    </div>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                        <button className="btn btn-success mr-3 mb-1  col-3" onClick={downloadArtifactExcel}>Download Artifact Details</button>
                        {/* <CSVLink color="black" data={data1} headers={""}
                        filename={"surveyWiseUserResponseFullDetails.csv"}> */}
                        <span>
                            <span className="badge text-dark fs-4 me-2" style={{fontFamily:"monospace"}}>
                                <i className="fa fa-hashtag me-2" />
                                Total Count: - 
                            </span>
                            <span className="fs-3 fw-bold text-primary">{data1.length}</span>
                        </span>
                        <button className="btn btn-success mr-3 mb-1 float-right col-3" onClick={downloadResponseExcel}>Download Response Details</button>
                        {/* </CSVLink> */}
                    </div>

                </div>
                <DataTable
                    columns={columns}
                    data={data1}
                    noHeader
                    defaultSortField="_id"
                    defaultSortAsc={false}
                    // pagination
                    // selectableRows
                    highlightOnHover />


                <div className='w-100  powered-btn'>
                    <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>DoYourSurvey</b></div></a>
                </div>
            </div>
            <ToastContainer />
        </main >
    )

}

export default DownloadExcel