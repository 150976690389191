import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Survey from './pages/index';
import Report from './pages/downloadExcel';
import Image from './pages/ImageView';
import UploadExcel from './pages/uploadExcel';
import AvantiRiskSummary from './pages/riskSummaryForm';
import Edit from './pages/Edit';
import UploadImages from './pages/uploadImages';
import FillSurvey from './pages/fillSurvey';
import MainAdmin from './pages/MainAdmin';
import CountPage from './pages/CountPage';
import Error from './pages/Error';
import 'react-toastify/dist/ReactToastify.css';
import AddPartnerAndLocation from './pages/AddPartnerAndLocation';
import DownloadExcel from './pages/downloadExcel';
import DirectSurvey from './pages/ManPowerSurvey';
import ManpowerList from './pages/manpowerList';

function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Survey/>}/>
        <Route path="/fillDetails" element={<Survey />} />
        <Route path="/FillSurvey" element={<FillSurvey />} />
        <Route path="/avanti_info" element={<FillSurvey />} />
        <Route path="/downloadexcel" element={<Report />} />
        <Route path="/Image" element={<Image />} />
        <Route path='/uploadExcel' element={<UploadExcel />} />
        <Route path='/AvantiRiskSummary' element={<AvantiRiskSummary />} />
        <Route path='/edit' element={<Edit />} />
        <Route path='/uploadImages' element={<UploadImages />} />
        <Route path='/uploadBranchImages' element={<UploadImages />} />
        <Route path='/00918273645mainAdmin' element={<MainAdmin />}></Route>
        <Route path='/count' element={<CountPage />}></Route>
        <Route path='/addpartnerLocation' element={<AddPartnerAndLocation />}></Route>
        <Route path='/report' element={<DownloadExcel />}></Route>
        <Route path='/DirectSurvey/:id/:agentId' element={<DirectSurvey />}></Route>
        <Route path='/manpowerList' element={<ManpowerList />}></Route>

        <Route path='*' element={<Error/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
