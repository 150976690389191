import React, { useState, useRef, useEffect } from "react";
import Header from './Header.js'
import { toast, ToastContainer } from "react-toastify";
import API from "../utils/apiCalling.js";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Loader from "../utils/loader.js";

const UploadImages = () => {

    const api = new API

    const [partnerName, setPartnerName] = useState("");
    const [branchName, setBranchName] = useState("");
    const [loanNumber, setLoanNumber] = useState("");
    const [lucFiles, setLucFiles] = useState(null);
    const [otherFiles, setOtherFiles] = useState(null);
    const [count, setCount] = useState(0);
    const [partnerList, setPartnerList] = useState(null)
    const [branchList, setBranchList] = useState(null)
    const [branchImageFiles, setBranchImageFiles] = useState(null)
    const [loader, setLoader] = useState(false)
    const lucFileInputRef = useRef(null);
    const otherFileInputRef = useRef(null);
    const branchImageRef = useRef(null)

    const navigate = useNavigate()


    const handleLucFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setLucFiles(event.target.files);
        }
    };

    const handleOtherFileChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            setOtherFiles(event.target.files);
        }
    };

    const uploadImage = () => {
        if (lucFiles) {
            saveImage1(lucFiles);
        } else {
            toast.error("No LUC files selected");
        }
    };

    const uploadOtherImage = () => {
        if (otherFiles) {
            saveImage2(otherFiles);
        } else {
            toast.error("No Other files selected");
        }
    };

    const saveImage1 = async (images) => {
        setLoader(true)
        try {
            let files = new FormData();

            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const file = images[i];
                    const reader = new FileReader();

                    reader.readAsDataURL(file);
                    reader.onloadend = async () => {
                        try {
                            const img = new Image();
                            img.src = reader.result;

                            img.onload = async () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');

                                const maxPixelCount = 800000; 
                                let newWidth = img.width;
                                let newHeight = img.height;

                                const totalPixels = img.width * img.height;
                                if (totalPixels > maxPixelCount) {
                                    const scalingFactor = Math.sqrt(maxPixelCount / totalPixels);
                                    newWidth = Math.floor(img.width * scalingFactor);
                                    newHeight = Math.floor(img.height * scalingFactor);
                                }

                                canvas.width = newWidth;
                                canvas.height = newHeight;

                                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                                // canvas.width = img.width * 0.5;
                                // canvas.height = img.height * 0.5;
                                // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);


                                canvas.toBlob(async (blob) => {
                                    if (blob) {
                                        const formattedFileName = `${partnerName}-${branchName}-${loanNumber}-LUC-${i}.jpeg`;

                                        files.append('file', blob, formattedFileName);


                                        if (i === images.length - 1) {
                                            files.append('Id', loanNumber);
                                            files.append("artifactType", "LUC");

                                            console.log("save image 1 ka Payload to send to API:", files);
                                            let result = await api.putPostFileFun(`https://api.doyoursurvey.com:3009/Avanti/saveImage`, files);
                                            console.log(result, "resulttttttttttttt");

                                            if (result.code == 200) {
                                                setLoader(false)
                                                // lucFileInputRef.current.value=''
                                                if (totalCount && pageCount) {
                                                    navigate('/count', { state: { totalCount: totalCount, pageCount: pageCount, QDID: QDID, currentDate: currentDate } })
                                                }
                                                else {
                                                    navigate(-1)
                                                }
                                                toast.success(result.message);
                                            }
                                        }
                                    }
                                }, 'image/jpeg', 1); // Adjust quality as needed
                            };
                        } catch (fileError) {
                            setLoader(false)
                            console.error("Error processing file:", fileError);
                            toast.error("Error processing file for upload");
                        }
                    };

                    reader.onerror = () => {
                        toast.error("Error reading file");
                    };
                }
            }
        } catch (error) {
            console.error("Error during image upload:", error);
            toast.error("Error during image upload");
        }
    };


    const saveImage2 = async (images) => {
        setLoader(true)
        try {
            let files = new FormData();

            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    const file = images[i];
                    const reader = new FileReader();

                    reader.readAsDataURL(file); // Read the file as a data URL
                    reader.onloadend = async () => {
                        try {
                            const img = new Image();
                            img.src = reader.result; // Use the result from FileReader

                            img.onload = async () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');

                                  
                                const maxPixelCount = 800000; 
                                let newWidth = img.width;
                                let newHeight = img.height;

                                const totalPixels = img.width * img.height;
                                if (totalPixels > maxPixelCount) {
                                    const scalingFactor = Math.sqrt(maxPixelCount / totalPixels);
                                    newWidth = Math.floor(img.width * scalingFactor);
                                    newHeight = Math.floor(img.height * scalingFactor);
                                }

                                canvas.width = newWidth;
                                canvas.height = newHeight;

                                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                                // canvas.width = img.width * 0.5;
                                // canvas.height = img.height * 0.5;
                                // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                                // Convert the canvas to a Blob
                                canvas.toBlob(async (blob) => {
                                    if (blob) {
                                        const formattedFileName = `${partnerName}-${branchName}-${loanNumber}-Other-${i}.jpeg`;
                                        
                                        files.append('file', blob, formattedFileName);

                                        // Only send the request after the last image is processed
                                        if (i === images.length - 1) {
                                            files.append('Id', loanNumber);
                                            files.append("artifactType", "Other");

                                            let result = await api.putPostFileFun(`https://api.doyoursurvey.com:3009/Avanti/saveImage`, files);
                                            if (result.code == 200) {
                                                setLoader(false)
                                                // otherFileInputRef.current.value=""
                                                if (totalCount && pageCount) {
                                                    navigate('/count', { state: { totalCount: totalCount, pageCount: pageCount, QDID: QDID, currentDate: currentDate } })
                                                }
                                                else {
                                                    navigate(-1)
                                                }
                                                toast.success(result.message);
                                            }
                                        }
                                    }
                                }, 'image/jpeg', 1); 
                            };
                        } catch (fileError) {
                            setLoader(false);
                            console.error("Error processing file:", fileError);
                            toast.error("Error processing file for upload");
                        }
                    };

                    reader.onerror = () => {
                        setLoader(false);
                        toast.error("Error reading file");
                    };
                }
            }
        } catch (error) {
            setLoader(false);
            console.error("Error during image upload:", error);
            toast.error("Error during image upload");
        }
    };


    const finalUpload = () => {
        if (lucFiles && otherFiles) {
            uploadImage();
            uploadOtherImage()
        }
        else if (lucFiles) {
            uploadImage();
        }
        else if (otherFiles) {
            uploadOtherImage()
        }
        else {
            toast.error("No file selected ")
        }
    };

    const isUploadButtonDisabled = !lucFiles && !otherFiles;
    const isNewButtonDisabled = !(branchImageFiles && !partnerName == "" && !branchName == "" && !loanNumber == "")

    const location = useLocation();

    let { data, pageCount, loanNumberFromProp, propPartnerName1, propLocation1, totalCount, QDID, currentDate } = location.state || {};

    // console.log(data,"data", pageCount,"pageCount",loanNumberFromProp,"loanNumberFromProp", propPartnerName1,"propPartnerName1",propLocation1,"propLocation1",totalCount,"totalCount",QDID,"QDID", currentDate,"currentDate" )

    useEffect(() => {
        if (data) {
            setPartnerName(data['Enter Partner Name'])
            setBranchName(data['Location Name'])
            setLoanNumber(data['Please enter loan number'])
        }
        else if (loanNumberFromProp) {
            setPartnerName(propPartnerName1)
            setBranchName(propLocation1)
            setLoanNumber(loanNumberFromProp)
        }
    }, [])

    useEffect(() => {
        if (location.pathname === "/uploadBranchImages") {
            getpartnerList()
            // setPartnerName(data['Enter Partner Name'])

        }
    }, [])

    const getpartnerList = async () => {
        let result = await api.get(`https://api.doyoursurvey.com:3009/Avanti/getPartnerList`);
        if (result.code == 200) {
            setPartnerList(result.data)
        }
    }

    const getBranchList = async (e) => {
        setPartnerName(e.target.value)
        let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/getPartnerWiseLocation`, { partnerName: e.target.value });
        if (result.code == 200) {
            setBranchList(result.data.locations)
        }
    }

    const uploadBranchImage = async (images = branchImageFiles) => {
        let data = new FormData();
        setLoader(true)
        if (!partnerName || !branchName || !loanNumber) {
            setLoader(false)
            toast.error("Please enter details");
            return;
        }

        if (images && images.length > 0) {
            for (let i = 0; i < images.length; i++) {
                const file = images[i];
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    const img = new Image();
                    img.src = reader.result; // Use the result from FileReader

                    img.onload = async () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxPixelCount = 800000; 
                        let newWidth = img.width;
                        let newHeight = img.height;

                        const totalPixels = img.width * img.height;
                        if (totalPixels > maxPixelCount) {
                            const scalingFactor = Math.sqrt(maxPixelCount / totalPixels);
                            newWidth = Math.floor(img.width * scalingFactor);
                            newHeight = Math.floor(img.height * scalingFactor);
                        }

                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        // canvas.width = img.width * 0.5;
                        // canvas.height = img.height * 0.5;
                        // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                        // Convert the canvas to a Blob
                        canvas.toBlob(async (blob) => {
                            if (blob) {
                                const formattedFileName = `${loanNumber}-${branchName}-${partnerName}-${Date.now()}-${i}.jpeg`;
                                data.append('file', blob, formattedFileName);


                                if (i === images.length - 1) {
                                    data.append('partnerName', partnerName);
                                    data.append("location", branchName);
                                    data.append("comment", loanNumber);

                                    let result = await api.putPostFileFun(`https://api.doyoursurvey.com:3009/Avanti/uploadBranchImage`, data);
                                    if (result.code == 200) {
                                        setLoader(false)
                                        setLoanNumber('')
                                        branchImageRef.current.value = ''
                                        toast.success(result.message);
                                    }
                                }
                            }
                        }, 'image/jpeg', 1); // Quality can be adjusted
                    };
                };

                reader.onerror = () => {
                    setLoader(false)
                    toast.error("Error reading file");
                };
            }
        }
    };
    function goToCountDash() {
        navigate('/count', { state: { totalCount: totalCount, pageCount: pageCount, QDID: QDID, currentDate: currentDate } })
    }

    return (
        <>
            {loader && <Loader />}
            <Header />

            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card shadow-sm border-0">
                            <div className="card-header text-center">
                                {location.pathname == "/uploadBranchImages" && <h3>Upload Branch Images</h3>}
                                {location.pathname == "/uploadImages" && <h3>Upload Images</h3>}
                            </div>
                            <div className="card-body mt-2">
                                {location.pathname != "/uploadBranchImages" ? <> <div className="form-group row mb-4">
                                    <label className="col-md-4 col-form-label fw-bolder">Partner Name</label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" placeholder="Enter Partner Name" disabled value={partnerName} />
                                    </div>
                                </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Branch Name</label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" placeholder="Enter Branch Name" disabled value={branchName} />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Loan Number</label>
                                        <div className="col-md-8">
                                            <input type="number" className="form-control" placeholder="Enter Loan Number" disabled value={loanNumber} />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Upload LUC Image</label>
                                        <div className="col-md-8">
                                            <input type="file"
                                                className="form-control"
                                                accept='*'
                                                multiple
                                                onChange={handleLucFileChange}
                                                ref={lucFileInputRef}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Upload Other Image</label>
                                        <div className="col-md-8">
                                            <input type="file"
                                                className="form-control"
                                                accept='*'
                                                multiple
                                                onChange={handleOtherFileChange}
                                                ref={otherFileInputRef}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-8 text-center h-50">
                                        <button
                                            className="btn w-100"
                                            disabled={isUploadButtonDisabled}
                                            style={{ backgroundColor: "#ee6917", color: "white" }}
                                            onClick={(e) => finalUpload(e)}
                                        >
                                            Upload
                                        </button>
                                    </div> */}
                                    <div className="form-group row d-flex justify-content-center">
                                        <div className="col-md-8 text-center h-50">
                                            <button
                                                className="btn w-100"
                                                disabled={isUploadButtonDisabled}
                                                style={{ backgroundColor: "#ee6917", color: "white" }}
                                                onClick={() => finalUpload()}
                                            >
                                                Upload
                                            </button>
                                        </div></div>
                                </> : <>
                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Partner Name</label>
                                        <div className="col-md-8">
                                            <select className="form-control" onChange={(e) => getBranchList(e)}>
                                                <option hidden>Select Partner Name</option>
                                                {partnerList && partnerList.map((e) => {
                                                    return <option value={e}>{e}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Branch Name</label>
                                        <div className="col-md-8">
                                            <select className="form-control" onChange={(e) => setBranchName(e.target.value)}>
                                                <option hidden>Select Branch Name</option>
                                                {branchList && branchList.length > 0 && branchList.map((e) => {
                                                    return <option value={e}>{e}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div><div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Comment</label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" placeholder="Enter comment" value={loanNumber} onChange={(e) => { setLoanNumber(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <label className="col-md-4 col-form-label fw-bolder">Upload Branch Image</label>
                                        <div className="col-md-8">
                                            <input type="file"
                                                className="form-control"
                                                accept='*'
                                                multiple
                                                onChange={(event) => setBranchImageFiles(event.target.files)}
                                                ref={branchImageRef}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row d-flex justify-content-center">
                                        <div className="col-md-8 text-center h-50">
                                            <button
                                                className="btn w-100"
                                                disabled={isNewButtonDisabled}
                                                style={{ backgroundColor: "#ee6917", color: "white" }}
                                                onClick={() => uploadBranchImage()}
                                            >
                                                Upload
                                            </button>
                                        </div>

                                    </div>
                                    <div >
                                        <Link to={'/'}><button className="mainbtn">Go To Dashboard</button></Link>
                                    </div>

                                </>}


                                {/* <div className="col-md-4 h-50">
                                        <button
                                            className="btn btn-danger w-50"
                                            onClick={() => { resetForm() }}
                                            disabled={isResetButtonDisabled}
                                        >
                                            Reset
                                        </button>
                                    </div> */}
                                {pageCount && <div className="col-md-6 d-flex justify-content-start align-items-center" onClick={() => goToCountDash()}>
                                    <button
                                        className="btn btn-secondary w-100"
                                    >
                                        Go To Count Page
                                    </button>
                                </div>}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />


        </>
    )
}
export default UploadImages