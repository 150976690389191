import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Loader from '../utils/loader';

const AddPartnerAndLocation = () => {
    const [locationData, setLocationData] = useState([]);
    const [uploadExcelModel, setUploadExcelModel] = useState(false)
    const [partnerName, setPartnerName] = useState([]);
    const [loader, setLoader] = useState(false)
    const [ isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true)



    const handleFileChange = (event) => {
        setLoader(true)
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

                const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

                setPartnerName(jsonData[0]);
                const locationData = jsonData.slice(1).map((row) =>
                    row.reduce((acc, value, index) => {
                        acc[jsonData[0][index]] = value;
                        return acc;
                    }, {})
                );

                setLocationData(locationData);

            };

            reader.readAsBinaryString(file);
            setLoader(false)
            setIsUploadButtonDisabled(false)       
        }
    };


    const uploadExcelButton = async () => {
        setUploadExcelModel(false)
        setLoader(true)

        if (partnerName.length === 0 || locationData.length === 0) {
            setLoader(false)
            toast.error("Please upload a file first!");
            return;
        }

        const payLoad = { partnerName, locations: locationData };

        try {
            const response = await axios.post('https://api.doyoursurvey.com:3009/Avanti/addPartnerAndLocation', payLoad);
            if (response.code === 200) {
                toast.success('Upload and API Call Successful');
                setLoader(false)
            } 
            else {
                toast.error('API Call Failed');
                setLoader(false)
            }
        } catch (error) {
            toast.error('API Error: ' + error.message);
            setLoader(false)
        }
    };



    return (

        <>
            {loader && <Loader />}
            <ToastContainer />
            <div className="container mt-4">
                <h4 className="text-center mb-4">Add Partner and Location</h4>
                <div className="mb-3">
                    <label className="form-label"> <h5>Upload Excel :-</h5>

                        <input type="file" accept=".xlsx, .xls" className="form-control" onChange={handleFileChange} />

                    </label>
                    <button className='mainbtn ml-5' onClick={() => { setUploadExcelModel(true) }} disabled={isUploadButtonDisabled}>Upload</button>
                </div>

                <div className="table-responsive mt-4">
                    <table className="table table-bordered table-hover">
                        <thead className="table-light">
                            <tr>
                                {locationData.length > 0 && Object.keys(locationData[0]).map((key) => (<th key={key}>{key}</th>))}{console.log(locationData)}
                            </tr>
                        </thead>
                        <tbody>
                            {locationData && locationData.map((row, index) => (
                                <tr key={index}>
                                    {Object.values(row).map((value, i) => (<td key={i}>{value}</td>))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>


            {uploadExcelModel && <div class="modal fade show" style={{ display: "block" }} aria-labelledby="exampleModalLabel">
                <div class="modal-dialog">
                    <div class="modal-content" style={{ left: '2%', top: '7rem', borderRadius: "1.5rem" }}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Log out</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setUploadExcelModel(false)}></button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to upload Excel ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={() => setUploadExcelModel(false)}>No</button>
                            <button type="button" class="mainbtn" onClick={uploadExcelButton}>yes</button>
                        </div>
                    </div>
                </div>
            </div>}


        </>
    );
};

export default AddPartnerAndLocation;