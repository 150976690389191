import React from "react";
import { useLocation } from "react-router-dom";



function Header() {

    const url = useLocation()


    return (

        <>

            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(url.pathname === '/downloadAgencyexcel' || url.pathname === '/downloadYardexcel') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">
                        </ul>
                    </div></nav>
            </section>



        </>
    )

}
export default Header;