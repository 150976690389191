import React from "react";
import Header from "./Header";
import './riskSummary.css'

function AvantiRiskSummary() {
    return (
        <>


            <button className='saveButton-float'>Save</button>
            <button className='saveButton-float-1'>Save & Submit</button>
            <Header></Header>
            <div style={{ display: "flex", position: "relative" }}>
                <div style={{ backgroundColor: "rgb(255 159 76 / 40%)", height: "140rem", width: "3%", display: "flex", position: "absolute", left: "15px", top: "17px" }}>
                </div>
                <div style={{ marginTop: "2rem",  fontFamily: 'Calibri', display: "flex", position: "absolute" }}>
                    <div style={{ width: "90%", marginLeft: "5%" }}>
                        <div>
                            <table class="table table-bordered border-dark">
                                <thead >
                                    <tr>
                                        <th colspan="4" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>Partner Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Partner Name</td>
                                        <td> <input type='text' className='form-control'></input></td>
                                        <td>Area Covered in KM</td>
                                        <td> <input type='text' className='form-control'></input></td>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Avanti Finance: Loan Count</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Avanti Finance: Loan Value (Rs.)</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td>Zone</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Partner Own Funding: Loan Count</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td>Google Location</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Partner Own Funding: Loan Value (Rs.)</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div>
                            <table class="table table-bordered border-dark">
                                <thead >
                                    <tr>
                                        <th colspan="4" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>Risk Reviewer Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Name</td>
                                        <td> <input type='text' className='form-control'></input></td>
                                        <td>Target: Borrower Visit</td>
                                        <td> <input type='text' className='form-control'></input></td>
                                    </tr>
                                    <tr>
                                        <td >Contact Number</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Actual: Borrower Visit</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Reason: If Any Deficit</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td >Visit Start Date</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td>Visit End Date</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div>
                            <table class="table table-bordered border-dark">
                                <thead >
                                    <tr>
                                        <th colspan="4" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>Borrowers Visit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="col">Visit Type</th>
                                        <th scope="col">Target</th>
                                        <th scope="col">Achieved</th>
                                        <th scope="col">Remarks</th>
                                    </tr>
                                    <tr>
                                        <td >Last 6 Months</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td >Old 6 Months</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td >Other Visits</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                        <td> <input type='text' className='form-control' ></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h4>Branch Manpower as on Audit date</h4>
                            <div>
                                <table class="table table-bordered border-dark">
                                    <thead >
                                        <tr>
                                            <th colspan="7" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>ACTIVE: Manpower details including the Notice Period employees</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col"> Designation</th>
                                            <th scope="col">Qualification</th>
                                            <th scope="col"> Tenure with Partner (Month)</th>
                                            <th scope="col"> Tenure with Branch (Month)</th>
                                            <th scope="col"> Avanti Finance Portfolio (Yes/No)</th>
                                            <th scope="col"> Current Status (Active/Notice Period)</th>
                                        </tr>
                                        <tr>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h4>Is partner working with another BC partner (Yes/No)</h4>

                            <div>
                                <table class="table table-bordered border-dark">
                                    <thead >
                                        <tr>
                                            <th colspan="5" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>Other BC (business correspondents) Partner Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="col">Another BC Partner Name</th>
                                            <th scope="col">Status (Active Sourcing/Collection)</th>
                                            <th scope="col">Active Allocation Count</th>
                                            <th scope="col"> Active Allocation Value</th>
                                            <th scope="col"> Remarks</th>
                                        </tr>
                                        <tr>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div>
                            <h4>DPD Details as on Audit Date</h4>
                            <div>
                                <table class="table table-bordered border-dark">
                                    <thead >
                                        <tr>
                                            <th colspan="8" class="text-center" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>Due Payment Days Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>DPD Bucket</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}> 0-30</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>31-60</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}> 61-90</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}> 90+</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}> Total DPD Cases</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}> Due Amount (Rs.)</th>
                                            <th scope="col" style={{ backgroundColor: '#ffa6039e', color: 'black' }}>What Action taken by Branch for the DPD</th>
                                        </tr>
                                        <tr>
                                            <th> Financial</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <th> Migration</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <th> Medical Issue</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <th> Death</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <th> Ring Leader</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                        <tr>
                                            <th> Total Count</th>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                            <td> <input type='text' className='form-control' ></input></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>


    )
}


export default AvantiRiskSummary;