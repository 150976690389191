import React, { useEffect, useState, useRef } from 'react'
import Header from './Header.js'
import { toast, ToastContainer } from "react-toastify";
import API from "../utils/apiCalling.js";
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../utils/loader.js'
import Config from '../config/env'

function ManpowerList() {

    const api = new API

    const [partnerName, setPartnerName] = useState("");
    const [branchName, setBranchName] = useState("");
    const [noRecordFound, setNoRecordFound] = useState(false)
    const [partnerList, setPartnerList] = useState([])
    const [branchList, setBranchList] = useState(null)
    const [manpowerList, setManpowerList] = useState([])
    const [newManpowerList, setnewManpowerList] = useState([])
    const [newName, setNewName] = useState('')
    const [aadharNumber,set4DigitAadhar]= useState('')
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const [header, setHeader] = useState([])

    const navigate = useNavigate()



    useEffect(() => {
        getpartnerList()
    }, [])

    const getpartnerList = async () => {
        setLoader(true)
        let result = await api.get(`${Config.new.site_api}/Avanti/getPartnerList`);
        if (result.code == 200) {
            setPartnerList(result.data)
            setLoader(false)
        }
        else{
            setLoader(false)
        }

    }

    const getBranchList = async (e) => {
        setPartnerName(e.target.value)
        let result = await api.post(`${Config.new.site_api}/Avanti/getPartnerWiseLocation`, { partnerName: e.target.value });
        if (result.code == 200) {
            setBranchList(result.data.locations)
        }
    }

    const getManpowerList = async () => {
        setLoader(true)
        let data = {
            partner: partnerName,
            branch: branchName
        }

        const result = await api.post(`${Config.new.site_api}/Avanti/getManpowerList`, data)

        if (result.code == 200) {
            setNoRecordFound(false)

            setManpowerList(result.data)
            setHeader(Object.keys(result.data[0]))

            // toast.success(result.message, {
            //     position: toast.POSITION.TOP_RIGHT
            // });
            setLoader(false)
        }
        else {
            setNoRecordFound(true)
            setManpowerList([])
            setHeader([])
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }
    }


    const goToManpowerSurvey = (e) => {
        console.log(e)
        navigate("/DirectSurvey/672c5e45b474b9d630911372/" + e)
    }

    const disableSearch = !partnerName || !branchName


    const addNextToOpenModal = async () => {
        setModalOpen(true)
    }

    const addNextSurvey = async () => {
        setNewName('')
        set4DigitAadhar('')
        console.log("sakjfdbdjbsadksa  ")
        setModalOpen(false)
    }

    function showSubmit(){
        let flag=true;
        if(manpowerList.length==0){
            return false;
        }
        for(let i=0;i<manpowerList.length;i++){
            if(manpowerList[i].submitted==0){
                flag=false;
                break;
            }
        }
    return flag;
    }

    const searchByNameAndAadhar = async () => {

        let data = {
            name: newName,
            aadhaar: aadharNumber
        }

        const result = await api.post(`${Config.new.site_api}/Avanti/filterAgent`, data)

        if (result.code == 200) {
            setNoRecordFound(false)

            setnewManpowerList(result.data)
            

            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }
        else {
            setNoRecordFound(true)
            newManpowerList([])
            
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }

    }

    const allSubmit = async () => {
      
       let d=manpowerList.map((ele)=>{
           return ele._id
        })
      
        let data = {
            allSubmit: JSON.stringify(d)
        }

        const result = await api.post(`${Config.new.site_api}/Avanti/updateAllManpowerSurvey`, data)

        if (result.code == 200) {
            console.log(result,"result")
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }
        else {            
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }



    }
    return (
        <>
            <ToastContainer />
            {loader && <Loader />}

            <Header />

            <div className='row mt-2'>
                <div className="col-12 d-flex justify-content-end">
                    <Link to="/">
                        <button className="btn btn-secondary">Go To Dashboard</button>
                    </Link>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row mb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <label className="fw-bold mb-0">Partner Name</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" onChange={(e) => getBranchList(e)}>
                            <option hidden>Select Partner Name</option>
                            {partnerList && partnerList.map((partner, index) => (
                                <option key={index} value={partner}>{partner}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                        <label className="fw-bold mb-0">Branch Name</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" onChange={(e) => setBranchName(e.target.value)}>
                            <option hidden>Select Branch Name</option>
                            {branchList && branchList.length > 0 && branchList.map((branch, index) => (
                                <option key={index} value={branch}>{branch}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={() => getManpowerList()} disabled={disableSearch}>
                        Search
                    </button>
                </div>
            </div>


            {/* 
            <table className="table table-bordered mt-4">
                <thead>
                    <tr>
                        {header.slice(3).map((ele) => (
                            <th key={ele} className="fw-normal">{ele}</th>
                        ))}
                        <th className="fw-normal">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {manpowerList.map((item) => (
                        <tr key={item._id} style={{ backgroundColor: item.submitted === 1 ? "#cccccc" : "transparent" }}>
                            {header.slice(3).map((ele) => (
                                <td key={ele}>{item[ele] !== null ? item[ele].toString() : 'N/A'}</td>
                            ))}
                            <td>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    disabled={item.submitted === 1}
                                    onClick={() => goToManpowerSurvey(item._id)}
                                >
                                    Start
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table> */}

            {manpowerList.length > 0 && <table className="table table-bordered mt-4">
                <thead>
                    <tr className='text-center'>
                        {/* {header.slice(3).map((ele,ind) => (
                            <th key={ele} className="fw-normal">{ele}</th>
                        ))} */}
                        <th>Partner</th>
                        <th>Branch</th>
                        <th>Agent</th>
                        <th>Email</th>
                        <th>TOS</th>
                        <th>Pincode</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {manpowerList.map((item) => (

                        <tr key={item._id} className='text-center' style={{ backgroundColor: item.submitted === 1 ? "#cccccc" : "transparent" }}>
                            {/* {header.slice(3).map((ele) => (
                                <td key={ele}>{item[ele] !== null ? item[ele].toString() : 'N/A'}</td>
                            ))} */}
                            <td>{item.Partner}</td>
                            <td>{item.Branch}</td>
                            <td>{item.Agent}</td>
                            <td>{item.email}</td>
                            <td>{item.TOS}</td>
                            <td>{item.Pincode}</td>
                            <td>
                                <button
                                    className="btn btn-primary btn-sm w-75"
                                    disabled={item.submitted === 1}
                                    onClick={() => goToManpowerSurvey(item._id)}
                                >
                                    Start
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}

            <div className='d-flex justify-content-end mr-5 mt-4'>
                {showSubmit()&&<button className='btn btn-danger w-25 mx-2' onClick={()=>{allSubmit()}}>All Submit</button>}
                {manpowerList.length > 0 && <button className='btn btn-primary w-25 mx-3' onClick={() => { addNextToOpenModal() }}>Add Next </button>}
            </div>


            {noRecordFound && <div class="" style={{ height: "15rem" }}>
                <div class="empty-state__content">

                    <div class="empty-state__message my-4">No records Found</div>
                    <div>
                        <Link to={"/"}> <button className='btn' style={{ backgroundColor: "#ee6917", color: "white" }}>Go To Dashboard</button></Link>
                    </div>
                </div>
            </div>}


            {modalOpen && <div
                className="modal"
                style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                tabIndex="-1"
                role="dialog"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> Are you sure to add next survey</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={() => setModalOpen(false)}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span className='d-flex justify-content-around'>
                                <input type='text' className='form-control w-25' value={newName} placeholder='Enter name' onChange={(e)=>{setNewName(e.target.value)}}/>
                                <input type='text' className='form-control w-25' value={aadharNumber} placeholder='Aadhar last 4 digits' onChange={(e)=>{set4DigitAadhar(e.target.value)}}/>
                                <button className='btn btn-primary' onClick={() => { searchByNameAndAadhar() }}>Search</button>
                            </span>
                            <table className="table table-bordered mt-4">
                                <thead>
                                    <tr className='text-center'>
                                        <th>Partner</th>
                                        <th>Branch</th>
                                        <th>Agent</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newManpowerList.map((item) => (

                                        <tr key={item._id} className='text-center' style={{ backgroundColor: item.submitted === 1 ? "#cccccc" : "transparent" }}>
                                            <td>{item.Partner}</td>
                                            <td>{item.Branch}</td>
                                            <td>{item.Agent}</td>
                                            <td>{item.email}</td>
                                            <td>
                                                <button
                                                    className="btn btn-primary btn-sm w-75"
                                                    disabled={item.submitted === 1}
                                                    onClick={() => goToManpowerSurvey(item._id)}
                                                >
                                                    Start
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <span>


                                {noRecordFound && <div class="" style={{ height: "15rem" }}>
                                    <div class="empty-state__content">

                                        <div class="empty-state__message my-4">No records Found</div>
                                        <div>
                                            <Link to={"/"}> <button className='btn' style={{ backgroundColor: "#ee6917", color: "white" }}>Go To Dashboard</button></Link>
                                        </div>
                                    </div>
                                </div>}

                            </span>


                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setModalOpen(false)}
                            >
                                No
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => addNextSurvey()}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default ManpowerList
