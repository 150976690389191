import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import API from "../utils/apiCalling.js";
import Config from '../config/env'
import Loader from '../utils/loader.js';
import { ToastContainer, toast } from 'react-toastify';


function DownloadManpowerSurveyData() {


  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false)

  const api = new API

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoader(true)
    console.log(data, "llllllllllllll")
    try {
      let response = await api.get(`${Config.new.site_api}/Avanti/getManpowerSurveyDetails`);
      console.log("   response  ", response)
      if (response) {
        setData(response.data[0].questions);
        setLoader(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false)
    }
  };

  const downloadExcel = async () => {
    setLoader(true)
    let data = {
      survey_id: '672c5e45b474b9d630911372'
    };
    try {
        let response = await  fetch(`${Config.new.site_api}/Avanti/getAvantiManpowerSurveyWiseResponse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        setLoader(false);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'Manpower.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setLoader(false);
        console.error('Failed to download file');
      }
    
    }
    catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
    <ToastContainer/>
    {loader && <Loader/>}
      <div>
        <button onClick={() => { downloadExcel() }} className='btn btn-primary'>
          Download Excel
        </button>
      </div>
    </>
  )
}

export default DownloadManpowerSurveyData
