import React from 'react'
// import { message, Slider } from 'antd';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
// import { useHistory } from 'react-router-dom';
import Loader from '../utils/loader';
import ReactPlayer from 'react-player'
import { useLocation, Link } from "react-router-dom";
import Webcam from 'react-webcam'



function AddAgent() {
    const [Namefirst, setNamefirst] = useState()
    const [qdid, setQdId] = useState()
    const [form, setForm] = useState(false)
    const [role, setRole] = useState()


    const api = new API

    const setQDID = async () => {
        console.log(Namefirst, "       ", qdid, "        ",role);

        if (Namefirst === '' || Namefirst === undefined) {
            toast.error("Please Enter Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (qdid === '' || qdid === undefined) {
            toast.error("Please Enter QD ID", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (role === '' || role === undefined) {
            toast.error("Please select role", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            let data = {
                name: Namefirst,
                qd_id: qdid,
                role: role
            }
            const result = await api.post('https://api.doyoursurvey.com:3009/Avanti/avanti_info', data)
            if (result.code == 200) {
                setQdId('')
                setNamefirst('')
                setRole('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>

            <div className='row'>

                <div className='col-md-8 py-2'>
                    <span>Enter Name :</span>
                    <input className='form-control' value={Namefirst} onChange={(e) => setNamefirst(e.target.value)} placeholder='Enter your name'></input>
                </div>

                <div className='col-md-8 py-2'>
                    <span>Enter QD Id :</span>
                    <input className='form-control' value={qdid} onChange={(e) => setQdId(e.target.value)} placeholder='Enter your QD ID'></input>
                </div>

                <div className='col-md-8 py-2'>
                    <span>Select Role :</span>
                    <select
                        className='form-control'
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option hidden>Select Your Role</option>
                        <option value={'agent'}>Agent</option>
                        <option value={'admin'}>Admin</option>
                    </select>
                </div>

                <div className='col-12 py-2'>
                    <button className='py-2 mr-3 mainbtn' onClick={setQDID}>Submit</button>
                </div>


            </div>
            <ToastContainer/>
        </>
    )
}


export default AddAgent;