import React, { useState, useEffect } from 'react'
import { FaBars } from "react-icons/fa";
import Header from './Header'
import AdminPage from './Edit';
import ImageView from './ImageView';
import DownloadExcel from './downloadExcel';
import AddAgent from './addAgent'
import AddPartnerAndLocation from './AddPartnerAndLocation';
import ManpowerUpload from './ManpowerUpload';
import DownloadManpowerSurveyData from './DownloadManpowerSurveyData';

function MainAdmin() {

    const [count, setCount] = useState(0)

    useEffect(() => {
        setCount(1)
    }, [])

    const toggleNav = () => {
        const sidebar = document.getElementById("mySidebar");
        sidebar.classList.toggle("closed");

        if (window.innerWidth <= 768) {
            sidebar.classList.toggle("open");
        }
    };



    return (
        <>
            <Header />
            <div className='bodyMainAdmin'>
                <div id="mySidebar" class="sidebar" style={{ top: "10vh" }}>
                    <div class="sidebar-header">
                        <h3>Menu</h3>
                        <button class="toggle-btn" onClick={() => { toggleNav() }}>
                            <FaBars />
                        </button>
                    </div>
                    <a className={count == 1 ? "bg-orange text-white" : ""} onClick={() => { setCount(1) }} > <span>Add Agent</span></a>
                    <a className={count == 2 ? "bg-orange text-white" : ""} onClick={() => { setCount(2) }} href="/report" target="_blank" ><span>Report</span></a>
                    <a className={count == 3 ? "bg-orange text-white" : ""} onClick={() => { setCount(3) }} href="/Image" target="_blank" ><span>Image</span></a>
                    <a className={count == 4 ? "bg-orange text-white" : ""} onClick={() => { setCount(4) }} href="/edit" target="_blank" ><span>Edit</span></a>
                    <a className={count == 5 ? "bg-orange text-white" : ""} onClick={() => { setCount(5) }} href="/addpartnerLocation" target="_blank" ><span>Add Partner & Location</span></a>
                    <a className={count == 6 ? "bg-orange text-white" : ""} onClick={() => { setCount(6) }} > <span>Upload manpower</span></a>
                    <a className={count == 7 ? "bg-orange text-white" : ""} onClick={() => { setCount(7) }} > <span>Download Survey Data</span></a>
                </div>


                <div id="main">
                    {count == 1 && (
                        <div className='container'>
                            <AddAgent />
                        </div>
                    )}

                    {count == 2 && (
                        <div className='container'>
                            {/* <DownloadExcel/> */}
                        </div>
                    )}

                    {count == 3 && (
                        <div className='container'>
                            {/* <ImageView/> */}
                        </div>
                    )}

                    {count == 4 && (
                        <div className='container'>
                            {/* <AdminPage/> */}
                        </div>
                    )}
                    {count == 5 && (
                        <div className='container'>
                            {/* <AddPartnerAndLocation/> */}
                        </div>
                    )}

                    {count == 6 && (
                        <div className='container'>
                            <ManpowerUpload />
                        </div>
                    )}

                    {count == 7 && (
                        <div className='container'>
                           <DownloadManpowerSurveyData/> 
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default MainAdmin
