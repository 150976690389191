
import React from 'react'
// import { message, Slider } from 'antd';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
// import { useHistory } from 'react-router-dom';
import Loader from '../utils/loader';
import ReactPlayer from 'react-player'
import { useLocation, Link } from "react-router-dom";
import Webcam from 'react-webcam';
import SearchableDropdown from '../utils/SearchableDropdown';

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: 'user',
}

function FillSurvey(props) {

    const {
        // survey, 
        // setSurveyScreen,
        //  type, 
        profilePercent } = props;

    const [surveyScreen, setSurveyScreen] = useState(false)
    const [survey, setSurveys] = useState()
    const [type, setSurveyType] = useState('survey')
    const [surveyQues, setSurveyQues] = useState()
    const [quesNo, setQuesNo] = useState(0)
    const [user, setUserData] = useState("");
    const [button, setButton] = useState(false)
    const [answer, setTemporaryAns] = useState([])
    const [progress, setProgress] = useState(0)
    const path = useLocation();
    const [inputValue, setInputValue] = useState("")
    const [prefillData, setPrefillData] = useState({});
    const [loanNumber, setLoanNumber] = useState("")
    var [checkboxCount, setCount] = useState(0);
    const [eventRadio, setEvent] = useState("");
    const [radioOption, setRadioOption] = useState("");
    const [eventText, setEventText] = useState("");
    const [eventForm, setEventForm] = useState([])
    const [eventCheckbox, setEventCheckbox] = useState([])
    const [eventMatrix, setEventMatrix] = useState([])
    const [text_box, setTextBox] = useState(false)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [location, setLocation] = useState()
    const [month, setMonth] = useState()
    const [emailScreen, setEmailScreen] = useState(false)
    const [surveySubmited, setSurveySubmited] = useState(false)
    const [surveyStutas, setSurveyStutas] = useState()
    const [shareId, setShareId] = useState()
    const [doubleDrop, setDoubleDrop] = useState(["", ""])
    const [loader, setLoader] = useState(false)
    const [savedAns, setSavedAns] = useState([])
    const [actionId, setActionId] = useState([])
    const [previousQue, setPreveiousQue] = useState()
    const [dropdownAns, setDropdownAns] = useState()
    const [anslength, setAnslength] = useState([])
    const [starClass, setStarClass] = useState()
    const [form, setForm] = useState(false)
    const search = useLocation().search;
    const [Namefirst, setNamefirst] = useState()
    const [qdid, setQdId] = useState()
    const [filee, setFile] = useState('')
    const [selectImage, setselectImage] = useState('no')
    const [picture, setPicture] = useState('')
    const [partnerForm, setPartnerForm] = useState(false)
    const [Images, SetImages] = useState([])
    const [newImage, setnewImage] = useState({})
    const [loanvalue, setLoanValue] = useState()
    const [count, setcount] = useState(0)
    const [idfcAgency, setIdfcAgency] = useState(false)
    const [checkBoxIndex, setCheckBoxIndex] = useState([])
    const [backQueAns, setBackQueAns] = useState([])
    const [textarea, setTextarea] = useState([])
    const [secondInputAns, setSecondInputAns] = useState([])
    const [questionIndex, setQuestionIndex] = useState([0])
    const [multiActId, setMultiActId] = useState([])
    const [form1, setForm1] = useState("")
    const [dependant_ques, setDependentQues] = useState([])
    const [form2, setForm2] = useState("")

    const [form3, setForm3] = useState("")

    const webcamRef = React.useRef(null)
    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot()

        setPicture(pictureSrc)
    })

    function saveImage1(e) {
        const formData1 = new FormData();

        formData1.append('id', '12');

        formData1.append('Other', e.target.file);

        formData1.append('LUC', e.target.file);

        setForm1(formData1)
        console.log(formData1)

    }

    console.log(questionIndex, "questionIndex")

    function saveImage2(e) {



        const formData2 = new FormData();

        formData2.append('id', '12');

        formData2.append('Other', e.target.file);

        formData2.append('LUC', e.target.file);

        setForm2(formData2)



    }



    function saveImage3(e) {

        const formData3 = new FormData();

        formData3.append('id', '12');

        formData3.append('Other', e.target.file);

        formData3.append('LUC', e.target.file);

        setForm3(formData3)

    }



    function formDataToObject(formData) {

        const object = {};
        formData.forEach((value, key) => {

            // If the value is a File, convert it to its name or other representation

            object[key] = value instanceof File ? { name: value.name, type: value.type } : value;

            console.log(value, "formData", key);
        });

        return object;

    }



    function callApi() {

        const formDataArray = [form1, form2, form3];

        console.log(formDataArray)
        const serializedFormDataArray = formDataArray.map(formDataToObject);
        console.log(serializedFormDataArray)
        const jsonString = JSON.stringify(serializedFormDataArray);
        console.log(jsonString)
    }


    const api = new API
    const goToNextQuestion = (question, option) => {
        setCount(0)
        let finalAns = answer
        if (question.type == 'matrix' && question.selection == 'single') {
            let tempAns = ""
            answer.forEach(ans => {
                tempAns = `${tempAns}field:${ans.bank}-Answer:${ans.answer},`
            })
            finalAns = [tempAns]
        }
        else if (question.type == 'note') {
            setButton(false)
            setQuesNo(quesNo + 1)
            return
        }
        else if (question.type == 'matrix' && question.selection == 'multiple') {
            let tempAns = ""

            answer.forEach(ans => {
                let matAns = ""
                ans.answer.forEach(an => {
                    matAns = `${matAns} ${an},`
                })
                tempAns = `${tempAns}field:${ans.bank}-Answer:${matAns},`
            })
            finalAns = [tempAns]
        }
        else if (question.type == 'form') {
            let tempAns = "";
            let fields = Object.keys(finalAns[0])
            fields.forEach(f => {
                tempAns = `${tempAns}${f}:${finalAns[0][f]},`
            })
            finalAns = tempAns
        } else if (question.type == 'star') {
            setQuesNo(quesNo + 1)
        }


        question.answer = finalAns;

        let finalActionId = [...actionId]
        if (question.selection == "multiple") {

            question.options.forEach((op) => {
                if (op.trigger && actionId.includes(op.action_id)) {
                    finalActionId = [op.action_id]

                }
            })
        }

        setTemporaryAns([]);
        if (quesNo + 1 === surveyQues.length || finalActionId.includes('final')) {
            // setSurveys(survey.questions)

            setSurveyStutas('complete')
            saveResponses('complete');
        }
        if (finalActionId.includes("exit")) {

            saveResponses('uncomplete')
        }
        else {
            let event
            for (let i = 0; i < surveyQues.length; i++) {
                if (finalActionId.includes(surveyQues[i]._id)) {
                    setPreveiousQue(quesNo)
                    questionIndex.push(i)
                    setQuesNo(i);
                    setActionId([])
                    setInputValue(surveyQues[i]?.prefill ? prefillData[surveyQues[i]?.object]?.[surveyQues[i]?.field] : "")

                    if (surveyQues[i]?.prefill) {
                        let data = prefillData[surveyQues[i].object]?.[surveyQues[i].field]
                        event = { target: {} }
                        event['target']['value'] = data
                    }
                    if (event) {

                        setAnswer(event, surveyQues[i].type, surveyQues[i].options[0], surveyQues[i], true)
                    }
                    break
                }

            }
            setButton(false)
            if (question.nextQutionButton === "yes") {
                setButton(true)
            }
        }
        if (question.type === 'radio') {
            eventRadio.target.checked = false
        } else if (question.type == 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type === 'matrix') {
            eventMatrix.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type === 'text') {
            eventText.target.value = ""
        } else if (question.type === 'number' || question.type === 'date') {
            eventText.target.value = ""
        }
        else if (question.type === 'form') {
            eventForm.forEach((eve) => {
                eve.target.value = "";
            })
        }
        else if (question.type === 'matrix') {
            eventRadio.target.checked = false

        }
        if (question.dependency && question.dependency == 'yes') {

            setSavedAns(answer);
        }

        setTextBox(false)
        setselectImage('no')
        setRadioOption("")
        setAnslength([])
        setDoubleDrop([])
        setStarClass()
        setCheckBoxIndex([])
        setselectImage('no')
        setTextarea([])
        setBackQueAns([])
        setSecondInputAns([])
        document.querySelectorAll("#que").forEach((val) => {
            if (val.checked) {

                val.checked = false
            }
            val.value = ""
        })
    }

    const submitEmail = () => {

        if (!qdid) {
            toast.error("Please Enter QD id", {
                position: toast.POSITION.TOP_RIGHT
            });

        }

        else {


            registerIfNewUser()
        }
    }
    console.log(questionIndex)
    const registerIfNewUser = async () => {
        let data = {
            // name: name,
            qd_id: qdid.toUpperCase()
        }
        const result = await api.post('https://api.doyoursurvey.com:3009/Avanti/SubmitSurvey', data)
        // const result = await api.post('https://api.doyoursurvey.com:3009/client/registerByEmail', data)
        if (result && result.code === 200) {
            if (path.pathname === '/IDFC/Agency') {
                setIdfcAgency(true)
                setEmailScreen(false)
                surveyDynamic('6454beffdaf7b26a6df961f7')
                setName(result.data[0].name)
            } else if (path.pathname === '/IDFC/Yard') {
                setIdfcAgency(true)
                setEmailScreen(false)
                surveyDynamic('646219aa0c022822ffccd6bb')
                setName(result.data[0].name)
            } else {
                setEmailScreen(false)
                surveyDynamic("660d3a4924494a7512c53d0e")

                let pData = { ...prefillData }
                pData['reviewerData'] = result.data[0]
                setName(result.data[0].name)
                setPrefillData(pData)
            }
        }
        else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const setQDID = async () => {
        if (Namefirst === '' || Namefirst === undefined) {
            toast.error("Please Enter Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (qdid === '' || qdid === undefined) {
            toast.error("Please Enter QD ID", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            let data = {
                name: Namefirst,
                qd_id: qdid
            }
            const result = await api.post('https://api.doyoursurvey.com:3009/Avanti/avanti_info', data)
            if (result.code == 200) {
                setQdId('')
                setNamefirst('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
    // "642a8c6792e2d50b453852fc"
    const surveyDynamic = async (id) => {
        setLoader(true)
        let data = {
            survey_id: id,
            email: email
        }
        const result = await api.post('https://api.doyoursurvey.com:3009/survey/getDynSurveyAttemp', data)
        if (result && result.code == 200) {
            setSurveys(result.data[0])
            setSurveyType(result.data[0].type)
            if (result.data[0].response_Limit === 0) {
                toast.error("This Survey Is Closed", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setSurveyScreen(false)
            } else {

                setSurveyScreen(true)
            }

            setSurveyQues(result.data[0].type === "matrix" ? result.data[0].questions[0] : result.data[0].questions)
            setLoader(false)


        }
        else {

            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }

    const saveProfile = async (user) => {
        setLoader(true)
        const data = {
            email: user.email,
            type: survey.survey_name,
            attempQuestionTask: JSON.stringify(surveyQues),
            profileCompletionPoint: 0,
            newPercentage: profilePercent + 10
        }
        const result = await api.post(`https://api.doyoursurvey.com:3009/user-panel-profile/userProfileTaskSubmit`, data);
        if (result) {
            setTimeout(() => {
                if (result.code == 200) {
                    setLoader(false)
                    setSurveyScreen(false)
                    toast.success('Profile Saved Succesfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    window.location.reload();
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }, 100)
        }
    }

    const saveResponses = async (status) => {

        setLoader(true)

        let result
        if (path.pathname === '/Avanti/Partner&Market') {


            let data = {
                reviewer_name: name,
                partner_name: email,
                location_name: location,
                month: month,
                questions: JSON.stringify(surveyQues)
            }

            result = await api.post('https://doyoursurvey.leadoye.com:3009/Avanti/partnerAndMarketSurvey', data);

        } else if (path.pathname === '/IDFC/Agency' || path.pathname === '/IDFC/Yard') {
            let data = {
                Id: qdid,
                Name: name,
                type: 'agency',
                questions: JSON.stringify(surveyQues)
            }
            if (path.pathname === '/IDFC/Yard') {
                data.type = 'yard'
            }

            result = await api.post('http://api.doyoursurvey.com:3009/Idfc/SubmitSurvey', data);
        }
        else {
            console.log("surveyQues", surveyQues)
            let data = {
                Id: qdid,
                Name: name,
                questions: JSON.stringify(surveyQues),
                que: surveyQues
            }

            result = await api.post('https://api.doyoursurvey.com:3009/Avanti/submitSurvey', data);
        }

        if (result.code == 200) {
            toast.success(result.message)
            setSurveyScreen(false)
            setSurveySubmited(true)
            setLoader(false)
            // getAvailableSurveys()
        } else {
            setSurveyScreen(false)
            // updateCount()
            toast.error(result.message)
            setSurveySubmited(true)
            setLoader(false)
        }
        // }
    }

    let lucidUrl
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        console.log()
        setUserData(userData);
        if (userData) {
            let pData = { ...prefillData }
            pData['reviewerData'] = userData
            setName(userData.name)
            setQdId(userData.qd_id)
            setPrefillData(pData)
        }

        lucidUrl = path.pathname
        if (lucidUrl === "/avanti_info") {
            setEmailScreen(false)
            setForm(true)
            let params = new URLSearchParams(search).get('shareId');
            setShareId(params)
        }
        if (lucidUrl === "/Avanti/Partner&Market") {
            setPartnerForm(true)
            setEmailScreen(false)

        }

        surveyDynamic("660d3a4924494a7512c53d0e")

    }, [])


    const getLoanDetails = async (survey, value) => {
        console.log("yuyuyuy")
        if (survey?.que_message == 'Please enter loan number' && value.toString().length == 13 && survey?.prefillDataRequired) {
            setLoanNumber(value)
            let data = {
                loan_number: value
            }
            let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/loanDetails`, data)
            if (result.code == 200) {

                setLoanValue(result.data[0])
                let pData = { ...prefillData }
                pData['loanData'] = result.data[0]

                setPrefillData(pData)

            }
        } else if (survey?.que_message == 'Please enter loan number' && value.toString().length == 13) {
            getPartnerList()

        } else if (survey?.que_message == 'Enter Partner Name' && value) {
            getLocation(value)
        }
    }

    const setAnswer = (event, type, option, question, selectedColumn, selection) => {

        if (option?.maxLength && event.target.value.length > option.maxLength) {
            event.target.value = event.target.value.slice(0, option.maxLength)
            return
        }
        if (backQueAns.length > 0) {
            setBackQueAns([])
            setTextarea([])
        }
        setInputValue(event.target.value)

        getLoanDetails(question, event.target.value)

        if (type === 'star') {

            setStarClass(event + 1)

            setButton(true)

            setTemporaryAns([...answer, event + 1])
            return
        }

        let actId = selectedColumn ? [] : [...actionId]

        if (event.target.checked || type == 'form' || type == 'text' || type === "number" || type === "date" || type === "dropdown") {
            if (type === "radio" && actId.length > 0) {
                actId = []
                actId.push(option.action_id)

            } else {
                actId.push(option.action_id)
            }
        } else {
            if (option.trigger) {
                let index = actId.indexOf(option.action_id)
                actId.splice(index, 1)

            } else {
                let index = actId.indexOf(option.action_id)
                actId.splice(index, 1)

            }
        }
        setActionId(actId)




        if (type == "radio") {

            if (option.Imagerequired === 'yes' || option.option == 'Yes') {
                setselectImage('yes')
            } else if (option.Imagerequired === 'yes' || option.option == 'No') {
                setselectImage('no')
            }
            question.options.forEach((op, index) => {
                if (selectedColumn !== index && op.textOpen === true) {
                    op.textOpen = false
                    option.selectedOption = false
                }
            })
            setEvent(event)
        } else if (type == "checkbox") {
            eventCheckbox.push(event);
            setEventCheckbox(eventCheckbox)
        }
        else if (type == "matrix") {
            eventMatrix.push(event);
            setEventMatrix(eventMatrix)
        }
        else if (type === "form") {
            eventForm.push(event)
            setEventForm(eventForm)
        }
        else {

            setEventText(event)
        }
        let ans = []

        if (type == 'checkbox' || question?.type == 'checkbox') {


            if (answer.length < 0) {
                setButton(false)
            }

            let answe = [...answer]
            if (option && option.text_box == 'yes') {
                // setTextBox(true)

                let { value, checked } = event.target;
                setButton(false)
                if (option.text_box == 'yes' && (option.option_type || option.secondInput) && type == 'text') {
                    event.checked = undefined
                    checked = undefined
                }

                if ((checked == true) || value == null || value == undefined) {
                    option.textOpen = true
                    option.selectedOption = true
                    let ans = [...answer]
                    ans.push(`${option.option}:`)
                    setTemporaryAns(ans)
                    answe = ans
                    setButton(false)
                } else if (checked == false) {
                    if (answer.join("").includes(event.target.value)) {
                        // let index = answer.indexOf(event.target.value)
                        // answer.splice(index, 1);
                        answer.forEach((an, i) => {
                            if (an.includes(option.option)) {
                                answer.splice(i, 1);
                            }
                        })
                        answe = answer
                    }
                    option.textOpen = false
                    option.selectedOption = false
                }
                else {
                    let findIndex
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            findIndex = i
                        }
                    })
                    if (option.secondInput) {
                        if (selectedColumn) {
                            let ans = answer[findIndex]
                            if (answer[findIndex].includes(':')) {
                                ans = answer[findIndex].split(':')[1]
                                answer[findIndex] = `${option.option}-${event.target.value}:${ans}`
                                answe = answer
                            } else {
                                answer[findIndex] = `${option.option}-${event.target.value}`
                                answe = answer
                            }
                        } else {
                            let ans = answer[findIndex]
                            if (answer[findIndex].includes(':')) {
                                ans = answer[findIndex].split(':')[0]
                                answer[findIndex] = `${ans}:${event.target.value}`
                                answe = answer
                            } else {
                                answer[findIndex] = `${option.option}:${event.target.value}`
                                answe = answer
                            }
                        }
                    } else {
                        answer[findIndex] = `${option.option}:${event.target.value}`
                        answe = answer
                    }
                    // answer[answer.length > 0 ? answer.length - 1 : answer.length] = `${option.option}:${event.target.value}`
                    if (checkboxCount == 0) {
                        setCount(1)
                    }

                    // setButton(true)

                }



                // return
            }
            else {
                if (answer.join("").includes(event.target.value)) {
                    // let index = answer.indexOf(event.target.value)
                    // answer.splice(index, 1);
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            option.selectedOption = false
                            answer.splice(i, 1);
                        }
                    })
                    answe = answer
                }
                else {
                    option.selectedOption = true
                    answe = [...answer, event.target.value]
                    setTemporaryAns([...answer, event.target.value])
                }

            }
            let butn = true
            if (answe.length == 0) {
                butn = false
            } else {
                for (let a = 0; a < question.options.length; a++) {
                    if (question.options[a].text_box == 'yes' && answe.join("").includes(question.options[a].option) && butn) {
                        answe.forEach((an, i) => {
                            if (an.includes(question.options[a].option) && answe[i].includes(':') && answe[i].split(':')[1].length == 0) {
                                butn = false
                                return
                            } else if (an.includes(question.options[a].option) && question.options[a].secondInput && answe[i].includes(':')) {
                                if (!answe[i].includes('-')) {
                                    butn = false
                                } else if (answe[i].split(':')[0].split('-')[1].length == 0) {
                                    butn = false
                                }
                            }
                        })
                    }
                    if (!butn) {
                        break
                    }
                }
            }
            if (butn) {
                setButton(true)
            } else (
                setButton(false)
            )
            return
        }
        else if (type === 'form') {

            let temp = answer.length > 0 ? answer[0] : {}

            if (selectedColumn) {
                doubleDrop[1] = event.target.value
            }
            else {
                doubleDrop[0] = event.target.value
            }

            temp[option.option] = option.text_box != 'yes' ? doubleDrop : event.target.value
            setTemporaryAns([temp])


            let keyInAnswer = Object.keys(temp)

            let notEmpty = true
            keyInAnswer.forEach(op => {
                if (!temp[op] || temp[op] == "") {
                    notEmpty = false
                }
            })
            if (question.options.length === keyInAnswer.length && notEmpty) {

                setButton(true)
            } else {
                setButton(false)
            }


        }
        else if (type == 'video') {

            setButton(true)
        }

        else {
            if (option && option.text_box == 'yes') {
                option.textOpen = true
                ans = answer
                setButton(false)
                if ((type == 'radio' || event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes' && !option.secondInput) {
                    setButton(false)
                    setTemporaryAns(`${option.option}:`)
                }
                else {
                    let butn = true
                    if (type == 'radio') {
                        butn = false
                        setRadioOption(event.target.value)
                    }
                    else {

                        if (option.secondInput) {
                            if (selectedColumn) {
                                if (answer.includes(':')) {
                                    ans = answer.split(':')[1]
                                    setTemporaryAns(`${radioOption || option.option}-${event.target.value}:${ans}`)
                                    ans = `${radioOption || option.option}-${event.target.value}:${ans}`
                                } else {
                                    ans = `${radioOption || option.option}-${event.target.value}`
                                    setTemporaryAns(`${radioOption || option.option}-${event.target.value}`)
                                }
                                setSecondInputAns([event.target.value])
                            } else {
                                // let ans = answer
                                if (answer.includes(':')) {
                                    ans = answer.split(':')[0]
                                    setTemporaryAns(`${ans}:${event.target.value}`)
                                    ans = `${ans}:${event.target.value}`
                                } else {
                                    ans = `${ans.length > 0 ? ans : (radioOption || option.option)}:${event.target.value}`
                                    setTemporaryAns(ans)
                                }
                            }
                        } else {
                            ans = `${radioOption || option.option}:${event.target.value}`
                            setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
                        }
                        // setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
                    }

                    if (option.text_box == 'yes' && option.secondInput && ans.includes(option.option)) {
                        // answer.forEach((an, i) => {
                        if (ans.includes(option.option) && ans.includes(':') && ans.split(':')[1].length == 0) {
                            butn = false
                            return
                        } else if (ans.includes(option.option) && option.secondInput && ans.includes(':')) {
                            if (!ans.includes('-') || (option?.option_type == 'date' && ans.split('-').length < 4)) {
                                butn = false
                            } else if (ans.split(':')[0].split('-')[1].length == 0) {
                                butn = false
                            }
                        } else if (!ans.includes(':') || !ans.includes('-')) {
                            butn = false
                        }
                        // })
                    }
                    if (butn) {
                        setButton(true)
                    }
                }
                return
            }

            else {
                option.selectedOption = true
                ans.push(event.target.value)
                setTemporaryAns(ans)

                if (typeof event.target.value == "string" && event.target.value.trim() == "") {
                    setButton(false)
                }
                if (ans.length == 0) {
                    setButton(false)
                }
            }
        }
        if (type !== 'matrix' && type !== 'form' && type !== 'number') {
            if (typeof event.target.value == "string") {
                if (event.target.value.trim() == "") {
                    setButton(false)

                }

                if (event.target.value.trim() > "") {

                    setButton(true)
                }
            }
        } else
            if (type === 'number') {
                if (option?.minVlaue > Number(event.target.value)) {

                    setButton(false)
                    return

                } else if (option?.minLength > (event.target.value).length) {
                    setButton(false)
                    return
                } else if (option?.maxVlaue < Number(event.target.value)) {

                    setButton(false)
                    return

                } else if (option?.maxLength < (event.target.value).length) {

                    setButton(false)
                    return

                }
                else {
                    let matHeader = []

                    if (event.target.value === "") {
                        setButton(false)
                    } else {
                        setButton(true)
                    }
                }
            }
        if (type === "checkbox") {
            (actId.length == 0) && setButton(false)
        }
        // setButton(true)

    }

    const backQuestion = (question) => {
        setButton(false)
        setBackQueAns([])
        setTextarea([])
        // setSerial(serial - 1)
        let tempMulti = [...multiActId]
        for (let y = 0; y < tempMulti.length; y++) {
            if (tempMulti[y].id == question._id) {
                tempMulti[y].selected = true
            }
        }
        setMultiActId(tempMulti)
        if (question.type === 'checkbox' || question.type === 'radio') {
            question.options.forEach((op, index) => {
                if (op.textOpen === true) {
                    op.textOpen = false
                }
                if (op.selectedOption = true) {
                    op.selectedOption = false
                }
                // if (multiActId.includes(op.action_id)) {
                //     let index = multiActId.indexOf(op.action_id)
                //     multiActId.splice(index, 1)
                //     setMultiActId(multiActId)
                // }
            })
        }
        // for logic in back que.

        setPreveiousQue(questionIndex[questionIndex.length - 3])
        let index = questionIndex[questionIndex.length - 2]
        // surveyQues[index].options.forEach((op) => {
        //     let index = multiActId.indexOf(op.action_id)
        //     multiActId.splice(index, 1)
        //     setMultiActId(multiActId)
        // })



        setQuesNo(index)

        preFillBackAnswer(surveyQues[index])
        console.log(index, "indexindex")
        // (surveyQues[index]) 
        setButton(true)
        questionIndex.splice(questionIndex.length - 1, 1)

        delete question.answer
        if (surveyQues[index].type == 'cxNote') {

            setButton(false)
            // setSerial(serial)
        } else if (surveyQues[index].type == 'cxScore') {
            setButton(false)
        }

    }
    const preFillBackAnswer = (que) => {
        let actId = [...actionId]
        actId = []
        if (que && que.type == 'radio') {
            if (que.answer.includes(":")) {
                let ans = que.answer.split(":")
                // console.log(que.answer, "que.answer")
                // console.log(ans, "ansytytytytytytyt")
                if (que.answer.includes("-")) {
                    setTextarea([ans[1]])
                    let seconAns = ans[0]
                    seconAns = seconAns.split("-")
                    setBackQueAns(seconAns[0])
                    setSecondInputAns([seconAns[1]])
                    let answ = `${seconAns[0]}-${seconAns[1]}:${ans[1]}`
                    setTemporaryAns(answ)
                    que.options.forEach((option) => {
                        if (option.action_id != "" && seconAns[0] == option.option) {
                            // actId = []
                            actId.push(option.action_id)
                        }
                    })
                } else {
                    setBackQueAns(ans[0])
                    setTextarea([ans[1]])
                    let answ = `${ans[0]}:${ans[1]}`
                    setTemporaryAns(answ)
                    que.options.forEach((option) => {
                        if (option.action_id != "" && ans[0] == option.option) {
                            // actId = []
                            actId.push(option.action_id)
                        }
                    })
                }
            }
            que.options.forEach((op) => {
                if (op.text_box == "yes" && op.textOpen == false && que.answer.includes(op.option)) {
                    op.textOpen = true

                    return
                } else {
                    setBackQueAns(que.answer)
                    setTemporaryAns(que.answer)
                    que.options.forEach((option) => {
                        if (option.action_id != "" && que.answer == option.option) {
                            actId = []
                            actId.push(option.action_id)
                        }
                    })
                }
            })
        } else if (que && que.type == 'checkbox') {
            let arr = []
            let text = []
            let totalResponses = []
            let secondAns = []
            que.answer.forEach((ans) => {
                if (ans.includes(":")) {
                    let a = ans.split(":")
                    if (ans.includes("-")) {
                        let seconAns = a[0]
                        seconAns = seconAns.split("-")
                        arr.push(seconAns[0])
                        text[arr.indexOf(seconAns[0])] = a[1]
                        secondAns[arr.indexOf(seconAns[0])] = a[1]
                        setTextarea(text)
                        setSecondInputAns([seconAns[1]])
                        totalResponses.push(`${seconAns[0]}-${seconAns[1]}:${a[1]}`)
                    } else {
                        arr.push(a[0])
                        text[arr.indexOf(a[0])] = a[1]
                        setTextarea(text)
                        totalResponses.push(`${a[0]}:${a[1]}`)
                    }
                } else {
                    arr.push(ans)
                    totalResponses.push(ans)
                }

            })
            que.options.forEach((op) => {
                if (op.text_box == "yes" && op.textOpen == false && arr.includes(op.option)) {
                    op.textOpen = true
                }
            })
            setTemporaryAns(totalResponses)
            setBackQueAns(arr)
            // let multiId = [...multiActId]
            que.options.forEach((option) => {
                if (option.action_id != "" && arr.includes(option.option)) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })
            // setMultiActId(multiId)
        } else if (que && que.type == 'matrix') {

            let tempQue = []
            let matAns = []
            let totalResponses = []
            que.answer.map(e => {
                if (e) {
                    if (que.selection == "single") {
                        let newValue = (((e.toString()).replaceAll('-', ',')).slice(0, -1)?.split(","))
                        const result = [];
                        for (let i = 0; i < newValue.length; i += 2) {
                            const bank = newValue[i]?.split(':')[1];
                            const answer = newValue[i + 1]?.split(':')[1];
                            totalResponses.push({ bank, answer });
                            // if (!tempQue.includes(field)) {
                            tempQue.push(bank)

                            matAns[tempQue.indexOf(bank)] = answer
                            // }
                        }
                    }// totalResponses.push(result)
                    else if (que.selection == "random") {
                        // let tempQue = []
                        let matinputAns = []
                        // let totalResponses = []
                        let newValue = (((e.toString()).replaceAll('^', ',')).split(";"))
                        let ans = newValue.map(obj => JSON.parse(obj))
                        ans.forEach((e) => {

                            tempQue.push(e.bank)
                            matAns.push(e.quality)
                            matinputAns.push(e.answer)
                            totalResponses.push(e)
                        })
                        // seMmatInputValue(matinputAns)
                        // setTemporaryAns([ans])
                    }
                    else {
                        let newValue = (e.toString().replaceAll('-', ',')).slice(0, -1)?.split(",,")
                        for (let i = 0; i < newValue.length; i++) {
                            const bank = (newValue[i]?.split(':')[1]).split(",")[0];
                            let spl = newValue[i]?.split(':')
                            let answer = (newValue[i]?.split(':'))[newValue[i]?.split(':').length - 1].trim()
                            answer = answer.includes(",") ? answer.replace(" ", "").split(",") : [answer]

                            answer = answer.includes("") ? answer.filter(ans => ans != "") : answer
                            totalResponses.push({ bank, answer });
                            tempQue.push(bank)
                            matAns[tempQue.indexOf(bank)] = answer
                        }
                    }
                }
                setBackQueAns(tempQue)
                setTextarea(matAns)
                setTemporaryAns(totalResponses)
            })
        } else if (que && que.type == 'form' || que.type == 'contact') {
            let ans = que.answer
            ans = ans.includes("") ? ans.filter(ans1 => ans1 != "") : ans
            let labelArr = []
            let textArr = []
            let totalResponses = {}
            for (let i = 0; i < ans.length; i++) {
                let label = ans[i].split(":")[0]
                let text = ans[i].split(":")[1]
                labelArr.push(label)
                textArr.push(text)
                totalResponses[label] = text
                eventForm[i].target.value = text
                setEventForm(eventForm)
            }
            setBackQueAns(labelArr)
            setTextarea(textArr)
            setTemporaryAns([totalResponses])
        } else if (que && que.type == 'rating') {
            setTemporaryAns(que.answer)
            // setRatingValue(que.answer)
            que.options.forEach((option) => {
                if (option.action_id != "" && que.answer === option.option) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })
        } else if (que && (que.type == 'text' || que.type == 'number' || que.type == 'date' || que.type == 'dropdown')) {
            setTemporaryAns(que.answer)
            que.options.forEach((option) => {
                if (option.action_id != "") {
                    // actId = []
                    actId.push(option.action_id)
                }
            })
        } else {
            setTemporaryAns(que.answer)
            que.options.forEach((option) => {
                if (option.action_id != "" && que.answer === option.option) {
                    // actId = []
                    actId.push(option.action_id)
                }
            })

        }

        setActionId(actId)
    }
    const sevaImage = async () => {
        let data = {
            Id: prefillData.loanData.loan_number,
            Image: picture
        }
        let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/saveImage`, data)
        if (result.code == 200) {
            setFile('')
            setPicture('')
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }
    const checkAnswer = (option, type, question) => {

        let ans = [...backQueAns]
        if (type == 'checkbox' || type == 'radio') {
            if (question?.ansChecked) {

                let r = surveyQues.filter(e => { return e._id == question?.ansChecked })[0].answer
                if (r.includes(option.option)) {
                    if (!answer.includes(option.option)) {
                        answer.push(option.option)
                        setTemporaryAns(answer)
                    }
                    return true
                } else if (option.min <= Number(r[0]) && (option.max ? Number(r[0]) <= option.max : true)) {
                    if (answer.length == 0) {
                        setTemporaryAns([option.option])
                        if (option.action_id != '') {
                            setActionId(option.action_id)
                        }
                        if (option.related_question) {
                            let rel = [...dependant_ques]
                            if (type == 'radio') {
                                question && question.options && question.options.map((e) => {
                                    if (e.related_question && rel.includes(e.related_question[0])) {
                                        rel.splice(rel.indexOf(e.related_question[0], 1))
                                    }
                                })
                            }
                            for (let t = 0; t < option.related_question.length; t++) {

                                rel.push(option.related_question[t])
                            }
                            setDependentQues(rel)
                        }
                        question.answer = [option.option]
                    }

                    return true
                }

            } else if (type == 'checkbox' && option.selectedOption) {
                return true
            } else if (ans.length == 0) {


            }
            else if (backQueAns.length > 0 && backQueAns.includes(option.option)) {
                return true
            } else {
                return false
            }

        }
    }
    const handleImageChange = (event) => {
        sevaImage1(event.target.files)
        console.log(event.target.files, "sdfsdfsdfdsfdsfsdf")

        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = () => {
        //     const base64Image = reader.result;
        //     setImageBase64(base64Image);
        // };
    };
    const sevaImage1 = async (Images) => {
        // let Images = filet
        setLoader(true)
        let LUC = new FormData()
        if (Images.length > 0) {
            for (let i = 0; i < Images.length; i++) {
                setcount(count + i)
                const file = Images[i];
                console.log(file, "uuuuuuu")
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    let data = new File([reader.result], `${'loanvalue.loan_number'}-${('loanvalue.branch').split(' ').join('_')}-${('loanvalue.partner').split(' ').join('_')}-${Date.now() + '-' + i}.jpeg`, { type: 'image/jpeg' })
                    // Use newFile to upload the renamed file
                    console.log("data", data)
                    await LUC.append('file', data);
                    if (i == Images.length - 1) {
                        //   await  LUC.append("Id", 12345)
                        let images = {
                            images: [{
                                LUC,
                                Id: 1234
                            }]
                        }

                        console.log(images, "[{newData}][{newData}]")
                        let result = await api.putPostFileFun(`https://api.doyoursurvey.com:3009/Avanti/saveImage`, images)
                        console.log(result)
                        if (result.code === 200) {
                            toast.success(result.message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])
                            setLoader(false)
                        }
                    }
                }
            }
        }

    }


    // const sevaImage1 = async (Images) => {
    //     setLoader(true); // Assuming setLoader is a state setter function

    //     try {
    //         let formData = new FormData();

    //         for (let i = 0; i < Images.length; i++) {
    //             const file = Images[i];
    //             const reader = new FileReader();

    //             // Wrap the reader logic in a promise for better handling
    //             const readFilePromise = new Promise((resolve, reject) => {
    //                 reader.onload = () => resolve(reader.result);
    //                 reader.onerror = (error) => reject(error);
    //                 reader.readAsArrayBuffer(file);
    //             });

    //             try {
    //                 const fileData = await readFilePromise;

    //                 // Create a new File object with a modified name
    //                 const newFileName = `${Date.now()}-${i}.jpeg`;
    //                 const newFile = new File([fileData], newFileName, { type: 'image/jpeg' });

    //                 // Append the newFile to formData
    //                 formData.append('file', newFile);

    //                 // Increment count or set it, assuming setcount is a state setter
    //                 setcount(count + 1);

    //                 if (i === Images.length - 1) {
    //                     // If this is the last file, prepare payload for API call
    //                     const payload = {
    //                         images: [{
    //                             LUC: formData,
    //                             Id: 1234  // Assuming this Id is related to the image upload context
    //                         }]
    //                     };

    //                     // Perform API call to upload images
    //                     const result = await api.putPostFileFun('http://localhost:3009/Avanti/saveImage', payload);

    //                     if (result.code === 200) {
    //                         toast.success(result.message, { position: toast.POSITION.TOP_RIGHT });
    //                         setFile('');
    //                         SetImages([]);
    //                     } else {
    //                         toast.error('Failed to upload images', { position: toast.POSITION.TOP_RIGHT });
    //                     }

    //                     // Reset loader state
    //                     setLoader(false);
    //                 }
    //             } catch (error) {
    //                 console.error('Error reading file:', error);
    //                 toast.error('Failed to read file', { position: toast.POSITION.TOP_RIGHT });
    //                 setLoader(false);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error setting up FormData:', error);
    //         toast.error('Failed to set up FormData', { position: toast.POSITION.TOP_RIGHT });
    //         setLoader(false);
    //     }
    // };
    function keyPressNumbers(event) {

        const keyCode = event.keyCode;

        const excludedKeys = [8, 37, 39, 46];

        if (!((keyCode >= 48 && keyCode <= 57) ||
            (keyCode >= 96 && keyCode <= 105) ||
            (excludedKeys.includes(keyCode)))) {
            event.preventDefault();
        }
    }
    const submitPartnerForm = () => {
        if (!name) {
            toast.error("Please Enter Reviewer Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!email) {
            toast.error("Please Enter Partner Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!location) {
            toast.error("Please Enter Location", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!month) {
            toast.error("Please Enter Month", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setPartnerForm(false)
            surveyDynamic("642e71d1061023c13a7cc3cf")
        }

    }

    const getPartnerList = async () => {
        let result = await api.get(`https://api.doyoursurvey.com:3009/Avanti/getPartnerList`)
        if (result.code == 200) {
            surveyQues[1].options[0].dropdown_option1 = result.data
        } else {
            toast.error("Please Enter Loan No. correctly", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const getLocation = async (partnerName) => {
        let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/getPartnerWiseLocation`, { partnerName: partnerName })
        if (result.code == 200) {
            surveyQues[2].options[0].dropdown_option1 = result.data.locations
        } else {
            toast.error("Please Enter partner Name correctly", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }


    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            {loader && <Loader />}
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(path.pathname === '/IDFC/Agency' || path.pathname === '/IDFC/Yard') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">

                        </ul>
                    </div></nav>
            </section>
            <section className='h-100 survey-screen container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 mb-5'>
                        <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                            {window.location.pathname == "/FillSurvey" && questionIndex == 0 && <Link to="/"><button className="btn btn-secondary">Dashboard</button></Link>}
                            {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                            <div className='row justify-content-center' >
                                {!surveySubmited && <>
                                    {emailScreen &&
                                        <div className='con col-md-6'>
                                            {/* <input type="file" name="file" accept='*' onChange={handleImageChange} multiple /> */}
                                            {/* <input type="file" onChange={(e) => { saveImage1(e, setForm1) }}></input>

                                            <input type="file" onChange={(e) => { saveImage2(e, setForm2) }}></input>

                                            <input type="file" onChange={(e) => { saveImage3(e, setForm3) }}></input>
                                            <button onClick={callApi}>callApi</button> */}
                                            <p className=''
                                            >General information</p>
                                            <label>Enter Qd Id :</label><br></br>
                                            <input className='form-control' onChange={(e) => setQdId(e.target.value)} placeholder='Enter your Qd Id'></input>
                                            {/* <label>Enter Name :</label><br></br> */}
                                            {/* <input className='form-control' onChange={(e) => setName(e.target.value)} placeholder='Enter your name'></input> */}
                                            <br /><br />
                                            <button className='py-2 mainbtn' onClick={submitEmail}>Submit</button>
                                            <a href='/uploadImages'><button className='py-2 mainbtn ml-2' >Upload Image</button></a>
                                        </div>
                                    }
                                    {partnerForm &&
                                        <div className='con col-md-6'>
                                            <form>
                                                <p className=''>General information</p>
                                                <label>Reviewer Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setName(e.target.value)} placeholder='Enter reviewer name'></input>
                                                <label>Partner Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setEmail(e.target.value)} placeholder='Enter partner name'></input>
                                                <label>Location Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setLocation(e.target.value)} placeholder='Enter loction'></input>
                                                <label>Month :</label><br></br>
                                                <input type='month' className='form-control' onChange={(e) => setMonth(e.target.value)} placeholder='Select month'></input>
                                                <br /><br />
                                                <button type="submit" className='py-2 mainbtn' onClick={submitPartnerForm}>Submit</button>
                                            </form>
                                        </div>
                                    }

                                    {!emailScreen && surveyScreen && !form && <>
                                        <div className='col-md-10 justify-content-center d-flex flex-column'>
                                            <p className={surveyQues && surveyQues[quesNo].que_message ? 'fs-5 fw-bold py-2 text-center mb-0' : 'fs-5 fw-bold py-2 text-center'}>{surveyQues && surveyQues[quesNo].question}</p>
                                            <spam className='fs-5 fw-bold py-2 text-center'>{surveyQues && surveyQues[quesNo].que_message}</spam>

                                        </div>
                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                            <textarea type="text" maxLength="255" value={surveyQues[quesNo].prefill ? (inputValue ? inputValue : answer[0] ? answer[0] : "") : answer[0] ? answer[0] : ""} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}
                                                disabled={surveyQues[quesNo].prefill ? true : ""}>
                                            </textarea>
                                            {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                option.Imagerequired == 'yes' && <> <button className='mainbtn mr-3 mt-2 text-white px-4' onClick={(e) => setFile("galley")} >Gallery</button>
                                                    {/* <button className='mainbtn mt-2 text-white px-4' onClick={(e) => setFile("camera")}>Camera</button> */}
                                                </>))}
                                        </div>)}

                                        {surveyQues && surveyQues[quesNo].type == 'date' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                            <input type={surveyQues[quesNo].selection} className="form-control col-md-10 mt-2" row="3" value={answer} placeholder="Enter Here ..." max={surveyQues[quesNo].maxDate ? eval(surveyQues[quesNo].maxDate) : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }} />

                                            {/* </textarea> */}
                                        </div>)}


                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'form' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                    <div className="form-check h-100">
                                                        {/* <textarea className="form-check-input fs-3" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        <div className='row w-100'>
                                                            <div className='col-md-8'>
                                                                <label className="fs-5 form-check-label w-100" >{option.option}</label></div>
                                                            <div className='col-md-4'>
                                                                {(option.option_type === "dropdown") ?
                                                                    <> <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                        <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                        {option.dropdown_option && option.dropdown_option.length > 0 && option.dropdown_option.map((op) =>
                                                                            <>
                                                                                <option value={op}>{op}</option></>
                                                                        )}
                                                                    </select>
                                                                        {(option.dropdown_option1 && option.dropdown_option1.length > 0) &&
                                                                            <select className='form-control mt-2' name="que2" id="que2" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], surveyQues[quesNo].selection) }} >
                                                                                <option value="" hidden>{(option.dropdown_option1_header) ? option.dropdown_option1_header : "Select Option"}</option>
                                                                                {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                                    <>
                                                                                        <option value={op}>{op}</option></>
                                                                                )}
                                                                            </select>}
                                                                    </> :
                                                                    <input type={option.option_type} maxLength="255" className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />}
                                                            </div>
                                                        </div>

                                                        {/* <textarea className="form-control col-md-10 mt-2" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        {/* {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option,surveyQues[quesNo]) }}>
                                                        </textarea>} */}
                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>



                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'dropdown' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                    <div className="form-check h-100 ">

                                                        <div className='row w-100'>
                                                            <div className='col-md-8'>
                                                                <label className="fs-5 form-check-label w-100" >{option.option}</label></div>
                                                            <div className='col-md-4'>

                                                                {option.dropdown_option1 && option.dropdown_option1.length > 0 &&
                                                                    <SearchableDropdown
                                                                        options={option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op, index) => { return ({ id: index, name: op }) })}
                                                                        label="name"
                                                                        id="que"
                                                                        selectedVal={(answer.length > 0 && answer[0]) ? answer[0] : ""}
                                                                        handleChange={(e) => setAnswer({ target: { value: e } }, surveyQues[quesNo].type, option, surveyQues[quesNo], surveyQues[quesNo].selection)}
                                                                        placeholder={(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'}
                                                                    />
                                                                    //  <select className='form-control' value={answer[0]} name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                    //     <option hidden>Select Option</option>
                                                                    //     {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                    //         <>
                                                                    //             <option value={op}>{op}</option></>
                                                                    //     )}
                                                                    // </select>
                                                                }
                                                            </div>
                                                        </div>

                                                        {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                        </textarea>}
                                                    </div>
                                                </div>
                                                ))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'checkbox' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3' >
                                                    <div className="form-check h-100 ">
                                                        <label className="fs-5 form-check-label  w-100" >
                                                            <input className="form-check-input fs-3" type="checkbox" value={option.option} id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} checked={checkAnswer(option, surveyQues[quesNo].type, surveyQues[quesNo])} />
                                                            {option.option}

                                                            {option.text_box == 'yes' && backQueAns.length > 0 && backQueAns.includes(option.option) ?
                                                                option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" maxLength={option?.maxLength ? option.maxLength : ""} value={textarea[backQueAns.indexOf(option.option)]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                    :
                                                                    option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" value={textarea[backQueAns.indexOf(option.option)]} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                    </textarea> :
                                                                option.option_type ? option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} maxLength={option?.maxLength ? option.maxLength : ""} onInput={(e) => { if (option.maxLength && e.target.value.length > option.maxLength) { e.target.value = e.target.value.slice(0, option.maxLength) } }} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                    : option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                    </textarea>}


                                                            {option.text_box == 'yes' && option.textOpen && option.secondInput && (backQueAns.includes(option.option) ? <input type={option.secondInput} maxLength="255" className="form-control col-md-10 mt-2" row="3" value={secondInputAns[backQueAns.indexOf(option.option)]} placeholder={(option.secondInputPlaceholder) ? option.secondInputPlaceholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo], option.secondInput) }} />
                                                                : <input type={option.secondInput} maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder={(option.secondInputPlaceholder) ? option.secondInputPlaceholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo], option.secondInput) }} />)
                                                            }
                                                        </label>
                                                    </div>

                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'video' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        <ReactPlayer url={option.option} controls={true} value={option.option} onEnded={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />

                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'note' && (<div className='row surv-op'>

                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        {/* <ReactPlayer url='http://localhost:3009/survey_video/UPI_AutoPay.mp4'  controls={true} value={option.option} onStart={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }}/> */}

                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>

                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'radio' && (
                                                <div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3'>

                                                        <div className="form-check h-100 ">
                                                            <label className="fs-5 form-check-label  w-100" >
                                                                <input className="form-check-input fs-3" type="radio" id="que" name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} checked={checkAnswer(option, surveyQues[quesNo].type, surveyQues[quesNo])} />
                                                                {option.option}


                                                                {/* {option.text_box == 'yes' && option.textOpen && (surveyQues[quesNo].selection == 'date') ? <input type={surveyQues[quesNo].selection} max={surveyQues[quesNo].option.maxDate ? eval(surveyQues[quesNo].option.maxDate) : ""} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, "text", option) }} />
                                                                    : option.option_type ? option.textOpen && <input type={option.option_type} maxLength="255" className="form-control col-md-10 mt-2 " row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                    </input> : option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                    </textarea>} */}

                                                                {backQueAns.length > 0 ? (option.text_box == 'yes' && option.textOpen && (surveyQues[quesNo].selection == 'date') ? option.textOpen && <input type={surveyQues[quesNo].selection} max={option?.maxDate ? eval(option?.maxDate) : ""} className="form-control col-md-10 mt-2" row="3" value={textarea[0]} placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, "text", option) }} />
                                                                    : option.option_type ? option.textOpen && <input type={option.option_type} value={textarea[0]} className="form-control col-md-10 mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                        : option.textOpen && <textarea type="text" maxLength="255" value={textarea[0]} className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                        </textarea>) :
                                                                    option.text_box == 'yes' && option.option_type ? option.textOpen && (surveyQues[quesNo].selection == 'date') ? <input type={surveyQues[quesNo].selection} max={option?.maxDate ? eval(option.maxDate) : ""} className="form-control col-md-10 mt-2" row="3" value={textarea[0]} placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, "text", option) }} />
                                                                        : option.textOpen && <input type={option.option_type} className="form-control col-md-10 mt-2" max={option?.maxDate ? eval(option?.maxDate) : ""} placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }} />
                                                                        : option.textOpen && <textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                                        </textarea>}

                                                                {backQueAns.length > 0 ? option.text_box == 'yes' && option.textOpen && option.secondInput && <input type={option.secondInput} maxLength="255" value={secondInputAns[0]} className="form-control col-md-10 mt-2" row="3" placeholder={(option.secondInputPlaceholder) ? option.secondInputPlaceholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo], option.secondInput) }} />
                                                                    : option.text_box == 'yes' && option.textOpen && option.secondInput && <input type={option.secondInput} maxLength="255" className="form-control col-md-10 mt-2" row="3" value={secondInputAns[0]} placeholder={(option.secondInputPlaceholder) ? option.secondInputPlaceholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo], option.secondInput) }}>
                                                                    </input>}
                                                                {option.Imagerequired == 'yes' && selectImage == 'yes' && <button className='mainbtn mr-3 text-white px-4' onClick={(e) => setFile("galley")} >Gallery</button>}
                                                                {/* {option.Imagerequired == 'yes' && selectImage == 'yes' && <button className='mainbtn text-white px-4' onClick={(e) => setFile("camera")}>Camera</button>} */}
                                                            </label>
                                                        </div>
                                                    </div>))}

                                                </div>)}

                                            {surveyQues && quesNo == surveyQues.length - 1 && button && (<div className='w-100 text-center my-4'>
                                                <a className='mainbtn text-white px-4' onClick={(e) => goToNextQuestion(surveyQues[quesNo])}>Submit</a>
                                            </div>)}

                                        </div>

                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == "number" && (<div className=""  >
                                                {(surveyQues[quesNo].selection === "mobile") ? <input type='number' maxLength={surveyQues[quesNo].inputLimit} className="form-control col-md-10 mt-2" placeholder="Enter Here ..." value={answer[0] ? answer[0] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0], surveyQues[quesNo]) }} /> :
                                                    <input type="number" className="form-control col-md-10 mt-2" placeholder="Enter Here ..." value={answer[0] ? answer[0] : ""} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0], surveyQues[quesNo]) }} />}
                                                {surveyQues[quesNo].options[0].Imagerequired === 'yes' && <button className='mainbtn mr-3 text-white px-4 mt-2' onClick={(e) => setFile("galley")}>Gallery</button>}
                                                {/* {surveyQues[quesNo].options[0].Imagerequired === 'yes' && <button className='mainbtn text-white px-4 ' onClick={(e) => setFile("camera")}>Camera</button>} */}


                                                {/* </input> */}
                                            </div>)}
                                        </div>

                                        <div className="col-md-12 ">
                                            {surveyQues && surveyQues[quesNo].type == 'star' && (<div className="">
                                                {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                <div className="quationSec w-100 position-relative mt-3">

                                                    <div className="optionsBox w-100 mb-5">
                                                        <div className="startReview1" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                            <ul className="d-flex p-0 startReview" >
                                                                {/* <li><a href="javascript:void(0);"><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>0</span></a>
                                                                    </li> */}
                                                                {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                    <li><a style={{ color: '#F8B84E ' }} onClick={(e) => setAnswer(index, surveyQues[quesNo].type, surveyQues[quesNo])}><i className={starClass >= index + 1 ? "fa fa-star" : "fa fa-star-o"} />
                                                                        <i className="fa fa-star" />
                                                                        <span>{option}</span></a>
                                                                    </li>))}
                                                                {/* <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={2} onClick={()=>setButton(true)}><i className="fa fa-star-o"  />
                                                                        <i className="fa fa-star" />
                                                                        <span>2</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={3} onClick={()=>setButton(true)}><i className="fa fa-star" />
                                                                        <i className="fa fa-star" />
                                                                        <span>3</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={4} onClick={()=>setButton(true)}><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>4</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={5} onClick={()=>setButton(true)}><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>5</span></a>
                                                                    </li> */}


                                                                {/* <li><a href="javascript:void(0);" className="tooltip swipIocn" id="swipIcon1" data-id="startReview2"><img src="../img/swip-icon.svg" height="28px" />
                                                                        <span className="tooltiptext ">Reverse Rating</span></a>
                                                                    </li> */}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>)}
                                        </div>


                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == "matrix" && (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, index) => (
                                                                <th scope="col" key={index}>{column} </th>

                                                            ))

                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {surveyQues[quesNo].options && surveyQues[quesNo].options.length > 0 && surveyQues[quesNo].options.map((row, index) => (
                                                            <tr>

                                                                <>
                                                                    <th scope="row">{row.option}</th>
                                                                    {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, i) => (
                                                                        <td>
                                                                            <div className="form-check">
                                                                                {surveyQues[quesNo].selection == 'single' && <input className="form-check-input" type="radio" value={column} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], row.option)} name={index} />
                                                                                }
                                                                                {surveyQues[quesNo].selection == 'multiple' && <input type="checkbox" name="checkbox" value={row.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} className="form-check-input" />
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                    ))}


                                                                </>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            {surveyQues && quesNo < surveyQues.length - 1 && (button || surveyQues[quesNo].prefill) && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >Next Question</button></div>)}

                                        </div>
                                    </>}
                                </>}
                                {surveySubmited && <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc">
                                            {(surveyStutas == 'complete') && <h3 style={{ color: "#ee6917" }}>Congratulations!! </h3>}
                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className=" text-center mx-auto d-block" style={{ height: '150px' }} autoPlay>
                                            </lottie-player>
                                            {/* <h4>Congratulations!!</h4> */}
                                            {(surveyStutas == 'complete') ? <p style={{ fontWeight: 600 }} className="mb-5">You have successfully submited the survey</p> : <p style={{ fontWeight: 600 }} className="mb-5">Thank You<br />You are not eligible for this survey</p>}
                                            <a className="mainbtn" data-bs-dismiss="modal" onClick={() => window.location.reload(false)}  >Add New from</a>
                                        </div>
                                    </div>
                                </div>}
                                <div className='d-flex flex-column align-items-center' style={{ gap: '15px' }}>
                                    {filee == "galley" && <input type="file" name="file" accept='*' onChange={handleImageChange} multiple />}
                                    {/* {filee == "galley" && Images && Images?.map(e=>{<img src={e} style={{ width: '100%', maxWidth: 400 }} />})} */}
                                    {filee == "galley" && Images.length !== 0 ? (
                                        <button onClick={sevaImage1} className="btn btn-primary"> Upload
                                        </button>
                                    ) : ""}
                                </div>

                                {filee == "camera" && <div>
                                    <div className='d-flex justify-content-center mb-4'>

                                        {picture == '' ? (
                                            <Webcam audio={false} height={400} ref={webcamRef} width={400} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />
                                        ) : (
                                            <img src={picture} />
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {picture != '' ? (
                                            <button
                                                onClick={sevaImage}
                                                className="btn btn-primary"
                                            >
                                                Upload
                                            </button>
                                        ) : (
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    capture()
                                                }}
                                                className="btn btn-danger"
                                            >
                                                Capture
                                            </button>
                                        )}
                                    </div>
                                </div>}
                                {/* {!surveyScreen && !surveySubmited &&!emailScreen&& <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc ">

                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className="mt-10 text-center mx-auto d-block" style={{ height: '150px' }} autoPlay>
                                            </lottie-player>
                                            <h3 className='mt-5' style={{ color: "#ee6917" }}>This Survey Is Closed </h3>
                                          
                                        </div>
                                    </div>
                                </div>} */}

                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100' style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                    <div className='container'>
                        <div className='row progress-survey w-100 bg-white com-bsl m-0 p-2'>
                            {surveyQues && quesNo > 0 && <div className='col-1 p-0'>
                                <div className='bg-blue h-100 d-flex align-items-center justify-content-center w-fit rounded-2'><i className="bi bi-chevron-left text-white fs-3" onClick={(e) => { backQuestion(surveyQues[quesNo]) }}></i></div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
            <div className='w-100  powered-btn'>
                <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>DoYourSurvey</b></div></a>
            </div>
            <ToastContainer />
        </main>
    )
}

export default FillSurvey

