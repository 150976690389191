import React from 'react';
import { useState, useEffect } from 'react';
import API from '../utils/apiCalling';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import axios from 'axios';
import Loader from '../utils/loader';

const ImageView = () => {
  let api = new API()
  let raw = []
  const [artifactType, setArtifactType] = useState('')
  const [image, setImage] = useState([])
  const [loanNo, setLoanNo] = useState('')
  const [secondsearch, setSecondSearch] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [pageNo, setPageNo] = useState(0)
  const [hideButton, setHideButton] = useState(true)
  const [location, setLocation] = useState('')
  const [branch, setBranch] = useState('')




  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getImage()
  }, [])


  // const setartifact = async (value) => {
  //   setLoading(true)
  //   let data = {
  //     artifactType: value
  //   }
  //   if (value == 'Both') {
  //     getImage()
  //   } else {
  //     let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/GetImageFilter`, data)
  //     setLoading(false)
  //     if (result.code === 200) {
  //       setImage(result.data)
  //     } else {
  //       setImage(result.data)
  //     }
  //   }


  // }

  // const MonthWiseImage = async () => {
  //   // let data = {
  //   //   date: MonthWise
  //   // }
  //   setLoading(true)
  //   setImage([])
  //   let newPics = raw.filter((e) => {


  //     if (MonthWise == e.date.slice(0, 7)) {
  //       return e
  //     }
  //   })
  //   setImage(newPics)
  //   setLoading(false)

  // }

  const getImage = async (no, clear) => {
    setLoading(true)
    setImage([])
    let data
    if (no || no === 0) {
      data = {
        pageNo: no,
        limit: 20,
        Image: clear ? '' : loanNo
      }
      setPageNo(no)
    } else {
      data = {
        pageNo: pageNo,
        limit: 20,
        Image: loanNo
      }
    }
    let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/searchImage`, data)
    if (result.code === 200) {
      setLoading(false)
      setImage(result.data)
    }
  }

  const getImageByloanno = () => {
    setLoading(true)
    setImage([])
    let newPics = raw.filter((e) => {
      if (e.Id == loanNo) {
        return e
      }
    })
    setImage(newPics)
    setLoading(false)
  }

  // const secondImage = async () => {
  //   setLoading(true)
  //   let data = {
  //     ImageArray: JSON.stringify(image),
  //     searchValue: secondsearch
  //   }
  //   setImage([])
  //   let result = await api.post('https://api.doyoursurvey.com:3009/Avanti/searchImagetwo', data)

  //   if (result.code === 200) {
  //     setImage(result.data)
  //     setLoading(false)
  //   }
  // }


  const findImage = async () => {
    setHideButton(false)
    setLoading(true)
    setImage([])
    let data = {}

    if (artifactType && artifactType !== 'Both') {

      data.type = artifactType

    }

    if (loanNo) {

      data.loanNo = loanNo

    }

    if (startDate && endDate) {

      data.startDate = startDate

      data.endDate = endDate

    } else if (startDate || endDate) {

      data.date = startDate || endDate

    }
    if (location || branch) {
      let arr = []
      if (location) {
        arr.push(location)
      }
      if (branch) {
        arr.push(branch)
      }
      data.nameFilter = arr
    }
    // console.log(data, "tttttt")
    let result = await api.post('https://api.doyoursurvey.com:3009/Avanti/mainFilter', data)
    setLoading(false)
    if (result && result.code == 200) {
      setImage(result.data)
    }

  }

  const handleDownload = (e) => {
    saveAs(e.Image, e.Id);
  }

  const handleDownloadImage = async () => {
    console.log("hii")
    let data = {
      ImageArray: JSON.stringify(image)
    }

    handleDownloadImage1(data)

  }

  const handleDownloadImage1 = async (data) => {
    try {
      let ImageArray = data.ImageArray

      setLoading(true);
      const response = await axios.post('https://api.doyoursurvey.com:3009/Avanti/downloadImage', {
        ImageArray
      }, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/zip' });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'images.zip';
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const donloadfilter = () => {
    let searchValue = 'shahpura'

    const matchedSites = image.filter((link) => {
      console.log(link)
      return link.Image.split('/').some((part) => part.includes(searchValue));
    });
    setImage(matchedSites)
    console.log(matchedSites, "matchedSites")

  }


  const createZipFile = async (imageUrls) => {
    const zip = new JSZip();
    // image=imageUrls
    for (let i = 0; i < imageUrls.length; i++) {
      const response = await fetch(imageUrls[i].Image);
      const blob = await response.blob();
      zip.file(`image_${i + 1}.jpg`, blob);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'images.zip');
    });
  };

  const clearFilter = () => {
    document.querySelectorAll("#input").forEach((val) => {

      val.value = ""
    })
    setBranch('')
    setLocation('')
    setHideButton(true)
    // setPageNo(0)
    setEndDate()
    setStartDate()
    setLoanNo()
    setSecondSearch([])
    setArtifactType()
    getImage(0, 'clear')
  }


  return (
    <>

      <div style={{ backgroundColor: "#8080801f" }}>
        <div className='row mt-2' >

          <div className='col-md-3 mb-3 pt-2 pl-5' style={{ paddingeft: "10rem" }}>
            <label className='mb-2 d-block'>Select Artifact</label>
            <div className='input-group w-100'>
              <select className='form-control' id='input' name="artifactType" value={artifactType} onChange={(e) => setArtifactType(e.target.value)}>
                <option value="" hidden>Select an option</option>
                <option value="LUC">Artifact</option>
                <option value="Other">Other Artifact</option>
                <option value="Both">Both</option>
              </select>
              {/* <div className="input-group-append">
      <span className="input-group-text">
        <i className="fa fa-chevron-down"></i>
      </span>
    </div> */}
            </div>
          </div>

          <div className='col-md-5 mb-3 pt-2'>
            <label className='mb-2 d-block'>Date Range</label>
            <div className='input-group'>
              <input className='form-control' type="date" id='input' placeholder='Start Date' name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              <span className='input-group-text'>to</span>
              <input className='form-control' type="date" id='input' placeholder='End Date' name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
          </div>

          <div className='col-md-4 mb-3 pt-2'>
            <label className='mb-2 d-block'>Enter Loan No</label>
            <div className='input-group w-75'>
              <input className='form-control' type='text' id='input' value={loanNo} onChange={e => setLoanNo(e.target.value)} placeholder='Search by Loan Number ...' />
            </div>

          </div>
        </div>
        <div className='row'>

          <div className='col-md-3 mb-3 pl-5' style={{ paddingeft: "10rem" }}>
            <label className='mb-2 d-block'>Enter Partner</label>
            <input className='form-control w-100' type='text' id='input' value={location} onChange={e => setLocation(e.target.value)} placeholder='Search Location' />
          </div>

          <div className='col-md-3 mb-3'>

            <label className='mb-2 d-block'>Enter Branch</label>
            <div className='input-group  w-100'>
              <input className='form-control' type='text' id='input' value={branch} onChange={e => setBranch(e.target.value)} placeholder='Search ...' />
            </div>
          </div>
          <div className='col-md-6 mb-3 mt-4'>
            <button className='py-2 mainbtn ml-3' onClick={findImage}>Find</button>
            <button className='py-2 mainbtn ml-2' onClick={clearFilter}>Clear Filter</button>
            <button className='btn bg-orange text-white  ml-2' onClick={handleDownloadImage}>All Download</button>
          </div>



        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center my-3 p-3 bg-light rounded shadow-sm">
        <span className="badge text-dark fs-4 me-2" style={{fontFamily:"monospace"}}>
          <i className="fa fa-image me-2"/> Total Images: - 
        </span>
        <span className="fs-3 fw-bold text-primary">{image.length}</span>
      </div>



      <div className='d-flex flex-wrap' style={{ marginTop: '3rem' }}>
        {loading && <Loader />}
        {image && image?.map((e) => {
          return (
            <div className='card ContentTap1' >
              <div className='UnnderItems text-center' >
                <img src={e.Image} alt='Img' height={180} width={240} />
                <p className='mb-0'>Loan Number <span>: {e.Id}</span></p>
                <p className='mb-0 text-orange' onClick={(q) => handleDownload(e)} style={{ cursor: 'pointer' }}>Download </p>
              </div>
              <div className='UnnderItems UnderSamers h-100'>
              </div>
            </div>)
        })}

        {hideButton && <div className='justify-content-center mb-5 mt-3' >
          <button disabled={pageNo == 0 && true} style={{ 'font-size': '24px', left: '23rem', position: 'relative' }} className='py-1 mainbtn ' onClick={() => { getImage(pageNo - 1) }}><i className='bi bi-chevron-double-left' /></button>
          <button style={{ 'font-size': '24px', left: '40rem', position: 'relative' }} className='py-1 mainbtn ml-5' onClick={() => { getImage(pageNo + 1) }}><i className='bi bi-chevron-double-right' /></button>
        </div>}
      </div>

    </>
  );
}

export default ImageView;