import { useState } from "react";
import { RingLoader } from "react-spinners";

const override = {
     position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" ,width:"100px",height:"100px"
     ,"z-index": "1"
};
const override2 = {
    position: "fixed", top: "65%", left: "48%", color:"#354C9E", transform: "translate(-50%, -50%)" ,width:"100px",height:"100px" 
 ,"z-index": "1"
};

function Loader() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState('black');

  return (
    <div className="sweet-loading=">


<RingLoader color="#ee6917"  style={override} />
<h3 className=""  style={override2}>Loading...</h3>
    </div>
  );
}

export default Loader;